@import "@/styles/variables.scss"; 







































































































































@mixin active {
  &:hover {
    background-color: var(--menu-background-active);
    color: var(--menu-color-active);

    i {
      color: var(--menu-color-active);
    }
  }

  &.is-active {
    background-color: var(--menu-background-active);
    color: var(--menu-color-active);

    i {
      color: var(--menu-color-active);
    }
  }

}

.side-bar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $base-z-index;
  width: $base-left-menu-width;
  height: 100vh;
  overflow: hidden;
  background: var(--bg-color);
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  transition: width $base-transition-time;

  &.is-collapse {
    width: $base-left-menu-width-min;
    border-right: 0;

    ::v-deep {
      .el-menu {
        transition: width $base-transition-time;
      }

      .el-menu--collapse {
        border-right: 0;

        .el-submenu__title {
          overflow: visible;
        }

        .el-submenu__icon-arrow {
          right: 10px;
          margin-top: -3px;
        }
      }
    }

  }

  ::v-deep {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    .el-menu {
      border: 0;
      background: rgba(255, 255, 255, 0);

      &.el-menu--popup {
        background: var(--menu-background);
      }

      .iconfont {
        padding-right: 3px;
        font-size: $base-font-size-default;
        color: var(--menu-color);
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
      }

      i {
        color: var(--menu-color);
      }
    }

    .el-menu-item,
    .el-submenu__title {
      height: 50px;
      overflow: hidden;
      line-height: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      color: var(--menu-color);
    }

    .el-menu-item,
    .el-submenu__title {
      @include active;
    }
  }
}
