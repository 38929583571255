.container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
}
.components-list {
  box-sizing: border-box;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
}
.components-list .components-item {
  display: inline-block;
  width: 48%;
  margin: 1%;
  transition: transform 0ms !important;
}
.components-draggable {
  padding-bottom: 10px;
}
.components-title {
  font-size: 14px;
  color: #222;
  margin: 6px 2px;
}
.components-title .svg-icon {
  color: #666;
  font-size: 18px;
}
.components-body {
  padding: 8px 18px;
  background: #fff;
  font-size: 12px;
  line-height: 18px;
  cursor: move;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}
.components-body .svg-icon {
  color: #777;
  font-size: 15px;
}
.components-body:hover {
  border: 1px dashed #787be8;
  color: #787be8;
}
.components-body:hover .svg-icon {
  color: #787be8;
}
.left-board {
  width: 300px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.left-scrollbar {
  height: 100%;
  padding: 0 0 8px 0;
  overflow: hidden;
  box-sizing: border-box;
  border-right: 1px solid #f1e8e8;
}
.center-scrollbar {
  text-align: left;
  height: 100%;
  overflow: hidden;
  border-left: 1px solid #f1e8e8;
  border-right: 1px solid #f1e8e8;
  box-sizing: border-box;
}
.center-board {
  height: 100%;
  width: auto;
  margin: 0 350px 0 300px;
  padding: 10px 0; /*add*/
  box-sizing: border-box;
  position: relative;
}
.empty-info {
  position: absolute;
  top: 33%;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  color: #ccb1ea;
  letter-spacing: 4px;
}
.action-bar {
  position: absolute;
  right: 0.5rem;
  display: flex;
  flex-direction: column;
  z-index: 99;
}
.action-bar .delete-btn {
  color: #f56c6c;
  margin-left: 0 !important;
}
.logo-wrapper {
  position: relative;
  height: 42px;
  background: #fff;
  border-bottom: 1px solid #f1e8e8;
  box-sizing: border-box;
}
.logo-wrapper .logo {
  position: absolute;
  left: 12px;
  top: 6px;
  line-height: 30px;
  color: #00afff;
  font-weight: 600;
  font-size: 17px;
  white-space: nowrap;
}
.logo-wrapper .logo > img {
  width: 30px;
  height: 30px;
  vertical-align: top;
}
.logo-wrapper .logo .github {
  display: inline-block;
  vertical-align: sub;
  margin-left: 15px;
}
.logo-wrapper .logo .github > img {
  height: 22px;
}
.center-board-row {
  padding: 12px 12px 15px 1px;
  box-sizing: border-box;
}
.center-board-row > .el-form {
  height: calc(100vh - 69px);
}
.drawing-board {
  height: 100%;
  position: relative;
}
.drawing-board .components-body {
  padding: 0;
  margin: 0;
  font-size: 0;
  min-height: 60px;
}
.drawing-board .sortable-ghost {
  position: relative;
  display: block;
  height: 60px;
  overflow: hidden;
  background: #fff;
}
.drawing-board .sortable-ghost::before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 3px;
  background: #5959df;
  z-index: 2;
}
.drawing-board .components-item.sortable-ghost {
  width: 100%;
  height: 60px;
  overflow: hidden;
  background-color: #f6f7ff;
}
.drawing-board .active-from-item > .el-form-item {
  background: #f6f7ff;
  border-radius: 6px;
  outline: 1px solid #38adff;
}
.drawing-board .active-from-item > .drawing-item-copy,
.drawing-board .active-from-item > .drawing-item-delete {
  display: initial;
}
.drawing-board .active-from-item > .component-name {
  color: #409eff;
}
.drawing-board .el-form-item {
  margin-bottom: 12px !important;
  overflow: hidden;
}
.drawing-item {
  position: relative;
  cursor: move;
}
.drawing-item.unfocus-bordered:not(.active-from-item) > div:first-child {
  border: 1px dashed #ccc;
}
.drawing-item .el-form-item {
  padding: 8px 10px;
}
.drawing-row-item {
  position: relative;
  cursor: move;
  box-sizing: border-box;
  outline: 1px dashed #ccc;
  border-radius: 3px;
  margin-bottom: 15px;
}
.drawing-row-item .drawing-row-item {
  margin-bottom: 2px;
}
.drawing-row-item .el-form-item {
  margin-bottom: 0;
}
.drawing-row-item .drag-wrapper {
  min-height: 80px;
  padding: 20px 0;
  overflow: hidden;
}
.drawing-row-item.active-from-item {
  border: 1px dashed #409eff;
}
.drawing-row-item .component-name {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  color: #bbb;
  display: inline-block;
  padding: 0 6px;
}
.drawing-item:hover > .el-form-item,
.drawing-row-item:hover > .el-form-item {
  background: #f6f7ff;
  border-radius: 6px;
  outline: 1px dashed #38adff;
}
.drawing-item:hover > .drawing-item-copy,
.drawing-row-item:hover > .drawing-item-copy,
.drawing-item:hover > .drawing-item-delete,
.drawing-row-item:hover > .drawing-item-delete {
  display: initial;
  background: #38adff;
}
.drawing-item > .drawing-item-copy,
.drawing-row-item > .drawing-item-copy,
.drawing-item > .drawing-item-delete,
.drawing-row-item > .drawing-item-delete {
  display: none;
  position: absolute;
  top: 0px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
}
.drawing-item > .drawing-item-copy,
.drawing-row-item > .drawing-item-copy {
  right: 56px;
  border-color: #409eff;
  color: #409eff;
}
.drawing-item > .drawing-item-copy:hover,
.drawing-row-item > .drawing-item-copy:hover {
  background: #409eff;
  color: #fff;
}
.drawing-item > .drawing-item-delete,
.drawing-row-item > .drawing-item-delete {
  right: 6px;
  border-color: #f56c6c;
  color: #fff;
}
/*# sourceMappingURL=src/components/jpgflow/DynamicForm/styles/home.css.map */