@import "@/styles/variables.scss"; 
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.formInfo {
    .formInfoDialog {
    }
    .design-tab > .el-tabs__header {
        margin: 0;
    }
}
.pagination-table {
    margin-left: 40px;
    border-collapse: collapse;
    th,
    td {
        border: 1px solid rgb(232, 236, 234);
        height: 48px;
        text-align: center;
        line-height: 48px;
        padding: 0 20px;
    }
}
.formInfoDialog ::v-deep .el-dialog__header {
    display: none;
}
.formInfoDialog ::v-deep .el-dialog__body {
    padding: 0;
}
::v-deep .el-drawer__body {
    flex: 1;
    overflow: auto;
    padding: 20px;
    padding-top: 0;
}

::v-deep .el-drawer__header {
    margin-bottom: 0;
}
::v-deep .sub-title {
    color: #fc6363;
}
::v-deep .el-form-item {
    margin-bottom: 8px;
}
.list-config {
    height: 560px;
}
.design-btn {
    // right: 20px;
    // position: absolute;
    // top: 6px;
    // z-index: 500;
}
.style-btn-item {
    width: 250px;
    height: 173px;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    margin-right: 15px;
    border: 1px solid#DCDFE6;
    &-cover {
        width: 100%;
        height: 100%;
        border-radius: 15px;

        object-fit: cover;
    }
    &:hover {
        border: 1px solid #1890ff;
    }
    &.active {
        border: 1px solid #1890ff;
    }
    &-label {
        position: absolute;
        bottom: 10px;
        text-align: center;
        width: 100%;
    }
}
.ptil {
    // text-align: center;
    font-size: 18px;
    border-bottom: #9e9e9e38 solid 1px;
    padding-bottom: 10px;
    margin-top: -7px;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
