@import "@/styles/variables.scss"; 














































































































































































































































































































































































































































































































































































































































































.header-small-mb .el-drawer__body {
    padding: 12px;
}

.ds-setting-drawer {
    right: 320px !important;
}
.setting-form{
    padding-right: 0 !important; ;
}
