@import "@/styles/variables.scss"; 

























































































































































































































.student-list {
    .text-center {
        text-align: center;
    }
    .flex-1 {
        flex: 1;
    }
    .d-flex {
        display: flex;
    }
    .align-items-center {
        align-items: center;
    }
    .justify-content-center {
        justify-content: center;
    }
    .py-10 {
        padding: 10px 0;
    }
    .mr-10 {
        margin-right: 10px;
    }
    .divider {
        border-bottom: 1px solid rgba(187, 187, 187, 1);
        padding-top: 10px;
    }
    .overflow-hidden {
        overflow: hidden;
    }
    .min-height {
        min-height: 0;
    }
    .min-width {
        // width: 120px;
        border: true;
    }
    .warp {
        flex: 1;
        overflow: hidden;
        table {
            padding: 0;
            tr:hover {
                background-color: rgba(255, 255, 255, 0.05);
            }
        }
    }
    .over-item:hover {
        cursor: pointer;
    }
}
