/deep/ .disUploadSty .el-upload--picture-card {
  display: none;
/* 上传按钮隐藏 */
}
.icon-item {
  width: 40px;
  height: 40px;
  margin: 6px;
  position: relative;
  cursor: pointer;
  opacity: 0.5;
}
.icon-item.active {
  opacity: 1;
}
.icon-item:hover {
  opacity: 1;
}
.setting-container {
  width: 600px;
  height: 100%;
  margin: auto;
  background: #fff;
  padding: 16px;
}
.setting-container >>>.el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
/*# sourceMappingURL=src/components/form/BasicSetting/index.css.map */