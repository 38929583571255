






































































































// 三点省略 支持单行多行
// Mixin { n:Number } n：省略行数限制
ellipsis(n) {
  overflow: hidden;
  text-overflow: ellipsis;

  if (n > 1) {
    display: -webkit-box;
    -webkit-line-clamp: n;
    -webkit-box-orient: vertical;
  } else {
    white-space: nowrap;
  }
}

.cmp-container {
  line-height: 30px;
  padding: 10px;

  >>> .el-input--small .el-input__inner {
    padding-left: 10px;
    padding-right: 15px;
  }

  >>> .el-input-number.is-controls-right .el-input__inner {
    padding-left: 15px;
    padding-right: 0;
    text-align: left;
  }

  >>> .el-input:hover .el-input__inner {
    border-color: #529eff;
  }
}

.label {
  font-size: 12px;
  padding-right: 16px !important;
  ellipsis(2);
}

.range-title {
  font-size: 12px;
  text-align: center;
  ellipsis(1);
}

.icon-wrapper {
  i {
    cursor: pointer;
    color: #c5c5c5;

    &:hover {
      color: #333;
    }
  }
}
