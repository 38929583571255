@import "@/styles/variables.scss"; 



































































































































































  @import "../../../../styles/global.scss"; /* form-item-wrapper已引入，还需要重复引入吗？ */
  .readonly-mode-field {
    display: inline-block;
    white-space: pre-wrap;
    line-height: 1.5;
  }
	.tree-container {
	  //padding: 5px;
	  margin: 2px;

	  .form-widget-list {
	    min-height: 28px;
	  }
	}
	.tree-container.selected {
	  outline: 2px solid $--color-primary !important;
	}
	.custom-tree-node {
	  flex: 1;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  font-size: 14px;
	  padding-right: 8px;
	}
