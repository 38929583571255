.svg-icon {
  float: right;
}
.form-typesel {
  display: flex;
}
.form-typeitem {
  flex: 1;
  height: 150px;
  text-align: center;
  justify-content: center;
  justify-items: center;
  border: 2px solid #eee;
  border-radius: 8px;
  margin: 0 9px;
  padding-top: 8px;
  cursor: pointer;
}
.form-typeitem:hover,
.form-itemselected {
  border-color: #1e90ff;
}
.form-typeitem .image {
  max-width: 100%;
  display: inline-flex;
}
/*# sourceMappingURL=src/components/form/DynamicForm/index.css.map */