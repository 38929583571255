@import "@/styles/variables.scss"; 




































































































































































































.dynamic-drawer {
  ::v-deep .el-drawer__header {
    margin-bottom: 10px;
  }

  ::v-deep .el-drawer__body {
    padding: 15px;
  }
}

