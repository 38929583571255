@import "@/styles/variables.scss"; 



















































.color-item:first-child {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        width: 24px;
        height: 1px;
        background-color: #d1cfcf;
        transform: translateY(10px) rotate(-45deg);
    }
}
.color-item {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    margin: 8px 0 0 16px;
    display: inline-block;
    border: 1px solid $base-border-color;
    &.is-active {
        box-shadow: 0 0 2px 2px #1890ff;
    }
}

.u-theme-picker {
    &.el-color-picker{
        
    }
    &.is-active{
        ::v-deep {
            .el-color-picker__trigger{
                box-shadow: 0 0 2px 2px #1890ff;
            }
        }
    }
    ::v-deep {
        .el-color-picker__trigger{
            width: 24px;
            height: 24px;
            margin: 8px 0 0 16px;
            border-radius: 50%;
            border: 1px solid $base-border-color;
            box-shadow: $base-box-shadow;
            background-image: url('../../../../assets/theme/pyq.png');
            background-size: 100% 100%;
            .el-color-picker__color{
                display: none;
            }
        }
    }
   
}
