@import "@/styles/variables.scss"; 









































































































































































.h-100 {
    height: 100%;
}
.el-row,
.gp-row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
}
.qz-login-container {
    height: 100vh;
    background: linear-gradient(#1890ff 50%, #ffffff 50%);
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    .qz-header-title {
        height: 80px;
        width: 100%;
        border-radius: 14px 14px 0 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        z-index: 1;
        .qz-header-logo {
            width: 30px;
            height: 39px;
            margin: 0 10px;
            margin-left: 40px;
        }
        .qz-title {
            font-size: 28px;
            font-weight: 500;
            padding-left: 30px;
            color: #ffffff;
            letter-spacing: 0.1em;
            text-shadow: 0 0 10px #2330a3;
            -webkit-text-shadow: 0 0 10px #2a3591;
            -moz-text-shadow: 0 0 10px #2a3591;
            -o-text-shadow: 0 0 10px #2a3591;
        }
    }

    .qz-login-btn {
        display: inherit;
        width: 96%;
        height: 42px;
        margin-top: 5px;
        border: 0;
        margin-right: auto;
        margin-left: auto;
        letter-spacing: 0.3em;
        font-size: 15px;
        &:hover {
            opacity: 0.9;
        }
    }

    .qz-login-form {
        position: relative;
        max-width: 100%;
        overflow: hidden;
        padding-bottom: 4.5vh;
        background-color: #ffffff;
        border-radius: 14px;
        box-shadow: 0 0 5px #aaaaab;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 20px;
        top: 50%;
        .qz-forget-password {
            width: 100%;
            margin-top: 40px;
            text-align: left;

            .qz-forget-pass {
                width: 129px;
                height: 19px;
                font-size: 20px;
                font-weight: 400;
                color: rgba(92, 102, 240, 1);
            }
        }
        /** 修改tabs下划线样式 */
        ::v-deep {
            .el-tabs__item {
                font-size: 16px;
                &.is-active {
                    font-weight: 600;
                }
            }
            .el-tabs__nav-wrap::after {
                background-color: transparent !important;
            }
        }

        &.row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: relative;
            top: 0;
            margin: auto;
        }
        .left-panel {
            //   width: 45%;
            width: 100%;
            //   height: 420px;
            &.disabled {
                opacity: 0.5;
            }
        }
        .right-panel {
            width: 45%;
            height: 420px;
        }
        .line {
            width: 0;
            height: 400px;
            border-right: 2px dotted #cececf;
        }

        &.school {
            // min-width: 600px;
            // width: calc(100% - 122px - 15px);
            /**学院风输入框样式 */
            ::v-deep {
                .el-form-item {
                    .qz-svg-container {
                        display: none !important;
                    }
                    .el-input {
                        border: #1890ff 1px solid;
                        input {
                            padding-left: 15px;
                            height: 50px;
                        }
                    }
                    .verifiy-code-input {
                        width: calc(100% - 122px - 15px);
                        min-width: 100px;
                    }
                    .qz-code-img {
                        position: relative !important;
                        display: inline-block !important;
                        margin-left: 15px;
                        vertical-align: middle;
                        top: 0;
                    }
                }
            }
        }
    }

    .qz-tips {
        margin-bottom: 10px;
        font-size: $base-font-size-default;
        color: $base-color-white;

        span {
            &:first-of-type {
                margin-right: 16px;
            }
        }
    }

    .qz-title-container {
        position: relative;

        .qz-title {
            margin: 0 auto 40px auto;
            font-size: 34px;
            font-weight: bold;
            color: $base-color-blue;
            text-align: center;
        }
    }

    ::v-deep {
        .el-form-item {
            margin: 10px 0;
            color: #454545;
            background: transparent;
            border: 1px solid transparent;
            border-radius: 2px;
            &__content {
                min-height: $base-input-height;
                line-height: $base-input-height;
            }

            &__error {
                position: absolute;
                top: 100%;
                left: 18px;
                font-size: $base-font-size-small;
                line-height: 18px;
                color: $base-color-red;
            }
        }

        .el-input {
            box-sizing: border-box;

            input {
                height: 58px;
                padding-left: 95px;
                font-size: $base-font-size-default;
                line-height: 58px;
                color: $base-font-color;
                /* background: #f6f4fc; */
                border: 0;
                caret-color: $base-font-color;
                border-bottom: 1px solid #eee;
            }
        }
    }
}
.qz-remember-password {
    margin-top: 20px;
    display: flex;
    margin-left: 10px;
}

.protocol {
    text-decoration: underline;
    color: #49b7fb;
}

.forgot-password {
    font-size: 13px;
    color: #888a88;
    margin-top: 20px;
    text-align: center;
    a {
        text-decoration: underline;
        color: #198afa;
    }
}

.code-content {
    margin-top: 30px;
    text-align: center;
    padding-bottom: 40px;
    .code-img {
        width: 164px;
        height: 164px;
    }
    .text {
        color: #000;
        font-size: 16px;
        margin-top: 20px;
        letter-spacing: 1px;
    }
}
