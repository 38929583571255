@import "@/styles/variables.scss"; 

































































































































































































































@import "../../../../styles/global.scss";

.design-time-bottom-margin {
  margin-bottom: 5px;
}

.field-wrapper {
  position: relative;

  .field-action{
    position: absolute;
    //bottom: -24px;
    bottom: 0;
    right: -2px;
    height: 22px;
    line-height: 22px;
    background: $--color-primary;
    z-index: 9;

    i {
      font-size: 14px;
      color: #fff;
      margin: 0 5px;
      cursor: pointer;
    }
  }

  .drag-handler {
    position: absolute;
    top: 0;
    //bottom: -22px;  /* 拖拽手柄位于组件下方，有时无法正常拖动，原因未明？？ */
    right: -1px;
    height: 20px;
    line-height: 20px;
    //background: $--color-primary;
    z-index: 9;

    i {
      font-size: 12px;
      font-style: normal;
      color: #fff;
      margin: 4px;
      cursor: move;
    }

    &:hover {
      //opacity: 1;
      background: $--color-primary;
    }
  }
}

.el-form-item {
  //margin-bottom: 0 !important;
  //margin-bottom: 6px;

  //margin-top: 2px;
  position: relative;

  ::v-deep .el-form-item__label {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ::v-deep .el-form-item__content {
    //position: unset;  /* TODO: 忘了这个样式设置是为了解决什么问题？？ */
  }

  span.custom-label i {
    margin: 0 3px;
  }

  /* 隐藏Chrome浏览器中el-input数字输入框右侧的上下调整小箭头 */
  ::v-deep .hide-spin-button input::-webkit-outer-spin-button,
  ::v-deep .hide-spin-button input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /* 隐藏Firefox浏览器中el-input数字输入框右侧的上下调整小箭头 */
  ::v-deep .hide-spin-button input[type="number"] {
    -moz-appearance: textfield;
  }
}

.label-wrap {
  ::v-deep .el-form-item__label {
    word-break: break-all;
    white-space: pre-wrap !important;
    line-height: 20px;
  }
}

.required ::v-deep .el-form-item__label::before {
  content: '*';
  color: #F56C6C;
  margin-right: 4px;
}

.static-content-item {
  min-height: 20px;
  display: flex;  /* 垂直居中 */
  align-items: center;  /* 垂直居中 */

  ::v-deep .el-divider--horizontal {
    margin: 0;
  }
}

.el-form-item.selected, .static-content-item.selected {
  outline: 2px solid $--color-primary;
}

::v-deep .label-left-align .el-form-item__label {
  text-align: left;
}

::v-deep .label-center-align .el-form-item__label {
  text-align: center;
}

::v-deep .label-right-align .el-form-item__label {
  text-align: right;
}


