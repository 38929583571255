@import "@/styles/variables.scss"; 









































.qz-footer-copyright {
  height: 55px;
  line-height: 55px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  letter-spacing: 2px;
  text-align: center;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  &.border {
    border-top: 1px dashed $base-border-color;
  }
}
