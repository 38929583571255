@import "@/styles/variables.scss"; 





























































































































































































.warp {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  padding: 5px 15px;
  table {
    padding: 0;
    color: #fff;
    font-size: 15px;
    tr {
      height: 30px;
      line-height: 30px;
    }
  }
}
.over-item:hover {
  cursor: pointer;
}
