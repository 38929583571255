@import "@/styles/variables.scss"; 






































































































































































































































































































@mixin active {
    &.is-active {
        background-color: var(--color-primary);

        .column-grid {
            /* background-color: var(--color-primary); */
            color: #fff;

            i {
                color: #fff;
            }
        }
    }
}

@mixin menu-active {
    &:hover,
    &.is-active {
        background-color: var(--column-menu-background);
        color: var(--color-primary);

        i {
            color: var(--color-primary);
        }
    }
}

.column-bar-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: $base-z-index;
    width: $base-left-menu-width;
    height: 100vh;
    overflow: hidden;
    box-shadow: 0 1px 6px rgb(0 21 41 / 8%);
    transition: width $base-transition-time;
    background-color: #fff;

    &.is-collapse {
        width: $base-left-column-width;

        ::v-deep {
            .title {
                width: 0;
                display: none;
            }
        }
    }

    ::v-deep {
        .el-tabs {
            position: fixed;
            box-shadow: 0 1px 4px rgb(0 21 41 / 9%);
            background: var(--menu-background);
            color: var(--menu-color);

            .el-tabs__nav {
                height: calc(100vh - #{$base-left-column-height});
            }
        }

        .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .el-tabs--left {
            .el-tabs__nav-wrap {
                &.is-left {
                    margin-right: 0 !important;
                }

                &.is-left::after {
                    width: 0;
                    background: #dcdfe6;
                }
            }

            .el-tabs__header {
                &.is-left {
                    margin-right: 0 !important;
                }

                .el-tabs__nav-scroll {
                    height: 100%;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 0;
                        height: 0;
                        background: transparent;
                    }
                }
            }
        }

        .el-tabs__active-bar.is-left {
            display: none;
        }

        .el-tabs__item {
            box-sizing: border-box;
            padding: 5px;
            height: auto !important;
            display: block;
            @include active;

            .column-grid {
                text-align: center;
                width: calc(#{$base-left-column-width} - 10px) !important;
                height: calc(#{$base-left-column-width} - 10px) !important;
                border-radius: 5px;
                color: var(--menu-color);
                padding-top: 10px;

                i {
                    display: block;
                    color: var(--menu-color);
                    height: 20px;
                    line-height: 20px;
                }

                span {
                    margin: 0;
                    padding: 0;
                    display: block;
                    line-height: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: middle;
                    // color: $base-color-black;
                }

                .svg-icon-w {
                    margin-right: 0;
                    display: block;
                    text-align: center;
                    // color: var(--menu-color);
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                }

                .fa {
                    display: block;
                    margin-right: 0;
                    text-align: center;
                }
            }
        }

        .el-menu {
            .el-divider__text {
                color: $base-color-black;
                width: 100px;
                text-align: center;
                padding: 0;
            }
        }

        .el-menu-column {
            left: calc(#{$base-left-column-width} + 4px);
            width: calc(
                #{$base-left-menu-width} - #{$base-left-column-width} - 6px
            );
            border: 0;
        }

        .el-menu-item,
        .el-submenu__title {
            height: 50px;
            overflow: hidden;
            line-height: 50px;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
            color: $base-color-black;

            i {
                color: $base-color-black;
                padding-right: 3px;
            }
        }

        .el-menu-item,
        .el-submenu__title {
            @include menu-active;
        }
    }
}
