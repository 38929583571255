@import "@/styles/variables.scss"; 





























































































































































































































































































































































































































































































































































































	.app-container {
		// .vxe-pager {
		// 	text-align: right;
		// 	// margin-top: 20px;
		// }

		.btn-orange {
			background-color: #FF9648;
			color: #FFF;
            border:none
		}


	}

	.font-size-14 {
		font-size: 14px;
	}

	.font-weight-400 {
		font-weight: 400;
	}

	.ml {
		&-10 {
			margin-left: 10px;
		}

		&-20 {
			margin-left: 20px;
		}
	}

	.flex-1 {
		flex: 1
	}

	.my {
		&-10 {
			margin: 10px 0;
		}

		&-20 {
			margin: 20px 0;
		}

		&-50 {
			margin: 50px 0;
		}

	}

	.mb-20 {
		margin-bottom: 20px
	}

	.d-flex {
		display: flex;


	}

	.align-items {
		&-center {
			align-items: center;
		}
	}

	.justify-content {
		&-center {
			justify-content: center;
		}

		&-between {
			justify-content: space-between;
		}

		&-end {
			justify-content: end;
		}
	}





	.template-record .vxe-tools--operate {
		.vxe-button {
			border: none !important;

			&:hover {
				border: none !important;
				box-shadow: none;

			}

			&:focus {
				border: none !important;
				color: #1890ff;
				box-shadow: none;
			}
		}

	}

	// .icon-:before {
	//   font-family: 'Your-Icon-Library';
	//   /* 其他样式设置 */
	// }
	.import-upload {
		width: 100%;
		margin-bottom: 15px;

		&-tips1 {
			// font-size: 12px;
			line-height: 1.5;
			color: #000;
			font-weight: 600;
			margin-bottom: 25px;
			// font-size: 15px;
		}

		&-tips {
			// font-size: 12px;
			line-height: 1.5;
		}

		.el-upload {
			width: 100%;

			.el-upload-dragger {
				width: 100%;
			}
		}

		&-list {
			width: 90%;
			margin: 0 auto;
			// padding-top: 10px;
		}

		&-form {
			display: flex;
			// justify-content: space-between;
			width: 100%;
			padding: 10px 0;

			&-item {
				flex: 1;

				.required-tips {
					font-size: 12px;
					margin-top: 5px;
					color: red;
					display: none;
				}
			}

			&-label {
				margin-bottom: 5px;

				.import-required {
					color: red;
					line-height: 14px;
				}
			}
		}
	}

	.import-success {
		text-align: center;
		padding: 50px 0;

		&-icon {
			font-size: 40px;
			color: #67C23A;
			margin-bottom: 10px;
		}

		&-tips {
			margin-top: 10px;

			.text-primary {
				color: #1890ff;
				cursor: pointer;
			}
		}
	}


	.avatar-uploader {

		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 178px;
		height: 178px;


		&:hover {
			border-color: #409EFF;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 178px;
			height: 178px;
			line-height: 178px;
			text-align: center;
		}

		.avatar {
			width: 178px;
			height: 178px;
			display: block;
		}

	}

	.column {
		margin-top: 10px;
		// border: 1px solid #d9d9d9;
		// border-radius: 6px;
		// padding: 15px;

		.column-list {
			// margin-top: 20px;
			padding: 5px 15px 15px 0;
			max-height: 300px;
			overflow-y: auto;




			&-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-radius: 5px;
				line-height: 1;
				padding: 10px;
				margin-top: 10px;
				border: 1px solid rgba(0, 0, 0, 0.05);
				background: #fff;

				.icon {
					position: relative;
					z-index: 9999;
				}

				// &:hover {
				// 	border: 1px solid #1890ff;
				// 	color: #1890ff;
				// }

			}
		}

	}


	// .custom-dragging {
	// 	background-color:#1890ff;
	// }

	.sortable-ghost {
		background: #fff !important;
		// opacity: 0 !important;

	}

	.sortable-chosen {
		.icon-caiddang {
			color: #1890ff;
		}

		background: #fff !important;
		border: 1px solid #1890ff;
		color: #1890ff;
		opacity: 1 !important;
	}
