@import "@/styles/variables.scss"; 



























































































































































































































































































.box-card {
    min-height: 80vh;
    max-height: 80vh;
    overflow-y: auto;
    padding: 15px;
}
.syslog-list {
    &-total {
        font-size: 20px;
        display: flex;
        align-items: center;
    }
    &-item {
        padding: 10px;
        margin: 16px 0;
        background: rgba($color: #000000, $alpha: 0.08);
        font-size: 15px;
        line-height: 1.7;
    }
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
::v-deep .el-card__body {
    padding: 0;
}
