@import "@/styles/variables.scss"; 










































































.set-theme {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 10;
}
.setting {
  i {
    font-size: 36px;
    color: #ffffff;
  }
}
.theme1 {
  background-image: linear-gradient(to right, blue, purple);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.theme2 {
  background-image: linear-gradient(to right, #1890ff, #1890ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.theme3 {
  background-image: linear-gradient(to right, #0a065e, #168ae3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bolder;
}

.gp-dropdown-menu {
  &__item {
    &.is-active {
      position: relative;
      background-color: #e6f3ff;
      color: #349ffc;
    }
  }
}
