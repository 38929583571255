@import "@/styles/variables.scss"; 












































































































.tab-container {
  //padding: 5px;
  // margin: 2px;

  .form-widget-list {
    min-height: 28px;
  }
}

.tab-container.selected {
  outline: 2px solid $--color-primary !important;
}

