@import "@/styles/variables.scss"; 




















































































.slot-wrapper-design {
  width: 100%;
  min-height: 26px;
  background: linear-gradient(45deg, #ccc 25%, #eee 0, #eee 50%, #ccc 0, #ccc 75%, #eee 0);
  background-size: 20px 20px;
  text-align: center;

  .slot-title {
    font-size: 13px;
  }
}

.slot-wrapper-render {

}

