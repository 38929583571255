@import "@/styles/variables.scss"; 





























































































































































































































































































































































































































































































































.item-container {
  width: 100%;
  display: flex;
  .item-left {
    flex: 1
  }
  .item-right {
    margin-left: 10px;
  }
}
