@import "@/styles/variables.scss"; 











































































































































































































































































.collapse-container {
  //padding: 5px;
  margin: 2px;

  .form-widget-list {
    min-height: 28px;
  }
}

.collapse-container.selected {
  outline: 2px solid $--color-primary !important;
}

::v-deep .el-collapsed__header {
  padding: 10px 12px;
}
