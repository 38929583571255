@import "@/styles/variables.scss"; 




































































































































.el-col-4 {
  min-width: 180px;
  max-width: 360px;
}

.app-card-container::after {
  content: "→";
  position: absolute;
  right: -30px;
  top: 50%;
  color: #03a9f4;
  font-weight: 900;
  font-size: 33px;
  transform: translateY(-50%);
}
.app-card-container:last-child::after {
  content: " ";
}
.app-card-popover {
  padding-bottom: 5px;
  .app-card-popover-content {
    .el-divider--horizontal {
      margin-bottom: 10px;
      margin-top: 0;
    }
    .el-descriptions-item__container {
      color: #909399;
    }
  }
}
.app-store-wrapper {
  .app-item {
    height: 30px;
    line-height: 30px;
    a {
      margin-left: 15px;
      &:hover {
        text-decoration: underline dotted;
      }
    }
    a:first-child {
      margin-right: 5px;
      margin-left: 0;
      text-decoration: none;
      /*color:#303133;*/
    }
    a.is-active {
      color: #1890ff;
      text-decoration: underline dotted;
    }
  }
  .app-item-condition {
    height: 33px;
    line-height: 33px;
    /*margin-top: 10px;*/
    margin-bottom: 5px;
  }
  .app-card-container {
    margin-left: 26px;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    .category {
      position: absolute;
      top: 10px;
      right: 15px;
    }
    .el-card {
      /*height: 260px;*/
      &:hover {
        border: 1px solid #1890ff;
      }
    }
    .app-card {
      .title {
        display: block;
        overflow: hidden;
        /*font-size: 14px;*/
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
      .bottom {
        margin-top: 13px;
        line-height: 12px;
        font-size: 13px;
      }
      .cost {
        padding: 0;
        float: right;
      }
      .image {
        width: 100%;
        height: 130px;
        line-height: 180px;
        text-align: center;
        color: #1890ff;
        font-size: 5em;
        display: block;
      }
      .clearfix:before,
      .clearfix:after {
        display: table;
        content: "";
      }
      .clearfix:after {
        clear: both;
      }
    }
  }
}
