.icon-ul {
  margin: 0;
  padding: 0;
  font-size: 0;
}
.icon-ul li {
  list-style-type: none;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  width: 16.66%;
  box-sizing: border-box;
  height: 108px;
  padding: 15px 6px 6px 6px;
  cursor: pointer;
  overflow: hidden;
}
.icon-ul li:hover {
  background: #f2f2f2;
}
.icon-ul li.active-item {
  background: #e1f3fb;
  color: #7a6df0;
}
.icon-ul li > i {
  font-size: 30px;
  line-height: 50px;
}
.icon-dialog ::v-deep .el-dialog {
  border-radius: 8px;
  margin-bottom: 0;
  margin-top: 4vh !important;
  display: flex;
  flex-direction: column;
  max-height: 92vh;
  overflow: hidden;
  box-sizing: border-box;
}
.icon-dialog ::v-deep .el-dialog .el-dialog__header {
  padding-top: 14px;
}
.icon-dialog ::v-deep .el-dialog .el-dialog__body {
  margin: 0 20px 20px 20px;
  padding: 0;
  overflow: auto;
}
/*# sourceMappingURL=src/components/jpgflow/DynamicForm/IconsDialog.css.map */