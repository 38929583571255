@import "@/styles/variables.scss"; 




















































































































































































































































































































































.el-drawer__body {
    padding: 20px;

    ::v-deep {
        .item-custom {
            display: block !important;
            min-height: 100px;
            float: left;
            margin-top: 34px;
            margin-left: -90px;
        }

        .el-radio-button {
            display: block;
            float: left;
            width: 80px;
            height: 80px;
            margin: 10px;
            cursor: pointer;
            border-radius: 5px;

            &.none {
                font-family: "iconfont", "element-icons" !important;
                font-size: 16px;
                font-weight: 580;
                line-height: 80px;
                text-align: center;
                background: #f7f7f7 none;
                background-size: cover;
            }

            &.none::before {
                content: "\e669";
            }

            &.background {
                background: url("../../../assets/theme/background-1.jpg");
                background-size: cover;
            }
            &.background2 {
                background: url("../../../assets/theme/background-2.png");
                background-size: cover;
            }

            &.is-active {
                box-shadow: 0 0 2px 2px #1890ff;
            }

            &.is-disabled {
                cursor: not-allowed;
                opacity: 0.6;
            }

            .el-radio-button__inner {
                display: none;
            }
        }
    }
}

.el-drawer__footer {
    border-top: 1px solid #dedede;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 0 0 20px;
    height: 50px;
    background: $base-color-white;
}

.el-drawer__wrapper {
    outline: none !important;

    * {
        outline: none !important;
    }
}

.el-drawer__header {
    margin-bottom: 0 !important;
}
