@import "@/styles/variables.scss"; 









































.upload-demo {
    ::v-deep {
        .el-upload {
            width: 100%;
            &__tip {
                color: #000 !important;
                i {
                    font-weight: bolder;
                }
            }
            &__tip-list {
                font-size: 12px;
                margin-top: 30px;
                color: #606266;
                ul {
                    padding-inline-start: 20px !important;
                    margin-block-start: .5em !important;
                    li {
                        margin-bottom: 6px;
                        line-height: 18px;
                    }
                }
            }
        }
        .el-upload-dragger {
            width: 100%;
        }
    }
}
.strong-text {
    color: $base-color-default;
    cursor: pointer;
}
