@import "@/styles/variables.scss"; 























































































































































































































































































































































































































































.design-btn {
    right: 20px;
    position: absolute;
    top: 6px;
    z-index: 500;
}
.flowDesignDialog {
    .el-dialog__header {
        display: none;
    }
    .el-dialog__body {
        padding: 5px 0 0;
    }
    .el-button.is-circle {
        border-radius: 50%;
    }
}
.design-tab > .el-tabs__header {
    margin: 0;
}
.flow-title {
    padding-left: 10px;
    font-weight: bold;
    color: #82848a;
}
