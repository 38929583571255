@import "@/styles/variables.scss"; 












































































.el-row.grid-container {
  min-height: 50px;
  //line-height: 48px;
  //padding: 6px;
  outline: 1px dashed #336699;

  .form-widget-list {
    min-height: 28px;
  }

}

.grid-container.selected, .grid-cell.selected {
  outline: 2px solid $--color-primary !important;
}

