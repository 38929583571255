@import "@/styles/variables.scss"; 















































































td.table-cell {
  display: table-cell;
  height: 36px;
  //border: 1px dashed #336699;
  border: 1px solid #e5e5e5;
}

