@import "@/styles/variables.scss"; 






















































































.avatar-dropdown {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    height: 50px;
    padding: 0;

    .user-avatar {
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 50%;
    }

    .user-name {
        position: relative;
        margin-left: 5px;
        margin-left: 5px;
        cursor: pointer;
    }
}
