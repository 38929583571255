@import "@/styles/variables.scss"; 














































































































































































@import "../../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//

.full-width-input {
  width: 100% !important;
}

.readonly-mode-rich-editor {
::v-deep .ql-toolbar {
  display: none;
}

::v-deep .ql-container {
//border-top: 1px solid #cccccc !important;
  border: 0;
}
}
