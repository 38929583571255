@import "@/styles/variables.scss"; 















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.dialog-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dialog-expand {
  display: flex;
  justify-content: end;
  align-items: center;
}
.dialog-expand-no {
  color: gray;
}
.dialog-tree {
  height: 300px;
  overflow-y: scroll;
}

.second {
  padding-right: 15px;
  .second-title {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 15px 15px 15px 0;
    margin-bottom: 20px;
  }
  .second-tree {
    height: 600px;
    overflow-y: scroll;
  }
}
.third {
  padding-left: 15px;
  .third-title {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 15px 15px 15px 0;
  }
  .third-content {
    background-color: #fafafa;
    margin: 10px;
    .third-content-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      .content-title-name {
        font-size: 18px;
        font-weight: 700;
      }
    }
    .third-tree {
      height: 300px;
      overflow: auto;
      background-color: #fafafa;
    }
  }
}

.left {
  width: 20%;
  transition: all 0.2s;
}
.left1 {
  width: 0%;
  display: none;
  transition: all 0.2s;
}
.right {
  width: 80%;
  transition: all 0.2s;
}
.right1 {
  width: 95%;
  transition: all 0.2s;
}
.imgright {
  right: 0;
  transition: all 0.2s;
  // background-color: #f5f5f5;
}
.imgright1 {
  left: 0;
  background-color: #ffffff;
  transition: all 0.2s;
}

.thirdLeft {
  width: 60%;
  transition: all 0.2s;
}
.thirdLeft1 {
  width: 95%;
  transition: all 0.2s;
}
.thirdRight {
  width: 40%;
  transition: all 0.2s;
}
.thirdRight1 {
  width: 0%;
  display: none;
  transition: all 0.2s;
}
