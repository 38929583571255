@import "@/styles/variables.scss"; 


























































































































































































































































.top-bar-container {
    display: flex;
    align-items: center;
    justify-items: flex-end;
    height: $base-top-bar-height;
    background: var(--bg-color);

    .qz-micro-main {
        width: 95% !important;
        ::v-deep {
            .el-menu {
                .iconfont {
                    padding-right: 3px;
                }

                &.el-menu--horizontal {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    height: $base-top-bar-height;
                    border-bottom: 0 solid transparent !important;
                    background: var(--bg-color) !important;

                    .el-menu-item,
                    .el-submenu__title {
                        padding: 0 15px;
                    }

                    @media only screen and (max-width: 767px) {
                        .el-menu-item,
                        .el-submenu__title {
                            padding: 0 8px;
                        }

                        li:nth-child(4),
                        li:nth-child(5) {
                            display: none !important;
                        }
                    }

                    > .el-menu-item {
                        height: $base-menu-item-height;
                        line-height: $base-menu-item-height;
                        margin-right: 4px;
                        background-color: var(--menu-background);
                        color: var(--menu-color);

                        i {
                            color: var(--menu-color);
                        }
                    }

                    > .el-submenu {
                        margin-right: 4px;

                        &.is-active,
                        &:hover {
                            > .el-submenu__title {
                                background-color: var(--menu-background-active);
                                color: var(--menu-color-active);
                                border-bottom: 2px solid
                                    var(--menu-color-active);

                                i {
                                    color: var(--menu-color-active);
                                }
                            }
                        }

                        .el-submenu__title {
                            height: $base-menu-item-height;
                            line-height: $base-menu-item-height;
                            background-color: var(--menu-background);
                            color: var(--menu-color);

                            i {
                                color: var(--menu-color);
                            }
                        }
                    }
                }

                &--horizontal {
                    .el-menu {
                        background-color: var(--menu-background);

                        .el-menu-item {
                            height: $base-menu-item-height;
                            line-height: $base-menu-item-height;
                            background-color: var(--menu-background);
                            color: var(--menu-color);

                            i {
                                color: var(--menu-color);
                            }

                            &.is-active,
                            &:hover {
                                background-color: var(--menu-background-active);
                                color: var(--menu-color-active);

                                i {
                                    color: var(--menu-color-active);
                                }
                            }
                        }

                        .el-submenu {
                            .el-submenu__title {
                                height: $base-menu-item-height;
                                line-height: $base-menu-item-height;
                                background-color: var(--menu-background);
                                color: var(--menu-color);

                                i {
                                    color: var(--menu-color);
                                }
                            }

                            &.is-active,
                            &:hover {
                                .el-submenu__title {
                                    background-color: var(
                                        --menu-background-active
                                    );
                                    color: var(--menu-color-active);

                                    i {
                                        color: var(--menu-color-active);
                                    }
                                }
                            }
                        }

                        &.el-menu--popup {
                            .el-submenu__title {
                                border-bottom: 0 solid transparent !important;
                            }
                        }
                    }

                    > .el-menu-item {
                        background-color: var(--menu-background);
                        color: var(--menu-color);

                        &.is-active,
                        &:hover {
                            background-color: var(--menu-background-active);
                            color: var(--menu-color-active);
                            border-bottom: 2px solid var(--menu-color-active);

                            i {
                                color: var(--menu-color-active);
                            }
                        }

                        .el-tag {
                            margin-top: calc(
                                #{$base-top-bar-height} / 2 - 7.5px
                            );
                            margin-left: 5px;
                        }

                        @media only screen and (max-width: 1199px) {
                            .el-tag {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .right-panel {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: $base-top-bar-height;

        ::v-deep {
            .user-name {
                color: var(--color-whitle);
            }

            .user-name + i {
                color: var(--color-whitle);
            }

            .el-badge {
                margin-right: 15px;
            }
        }
    }
}
