.cmp-container {
  line-height: 30px;
  padding: 10px;
}
.label {
  font-size: 12px;
  padding-right: 16px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.icon-wrapper {
  text-align: center;
}
.icon-wrapper >>> i {
  cursor: pointer;
  color: #c5c5c5;
}
.icon-wrapper >>> i:hover {
  color: #333;
}
/*# sourceMappingURL=src/components/jpgflow/Process/PropPanel/RowWrapper.css.map */