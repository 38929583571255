@import "@/styles/variables.scss"; 























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.dst-item{
   
    // padding: 6px 33px;
    
}
.dst-item .el-tag {
    // margin-left: 10px;

    // margin-top: -5px;
    // margin-right: 8px;
    cursor: pointer;
    vertical-align: middle;
    // margin-bottom: 5px;
}

.dstrow {
 
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 6px;
    .el-tag + .el-tag {
        margin-bottom: 0 !important;
    }
    // border: 1px solid #999;
}

.ds-list {
    ::v-deep .el-descriptions {
        width: 280px;
        overflow-x: hidden;
        margin-bottom: 15px;
        padding: 8px;
        background: #f5f7fa;
    }

    ::v-deep .el-descriptions__title {
        font-weight: normal;
    }

    ::v-deep .el-descriptions-item__label {
        width: 36px;
    }
}

.ds-button-wrapper {
    text-align: center;
    margin-top: 12px;
}

.ds-form {
    ::v-deep .el-form-item.is-required > .el-form-item__label:before {
        content: "" !important;
        margin-right: 0 !important;
    }

    ::v-deep .el-form-item.is-required > .el-form-item__label:after {
        content: "*" !important;
        color: var(--el-color-danger);
        font-weight: bold;
    }
}

.no-left-margin ::v-deep .el-form-item__content {
    margin-left: 0 !important;
}

.rh-row,
.rd-row {
    ::v-deep .el-col {
        margin: 5px 0;
    }
}

.ch-collapse ::v-deep .ace-editor {
    border: 1px solid #e1e2e3;
    min-height: 120px;
}

.dh-collapse ::v-deep .ace-editor {
    border: 1px solid #e1e2e3;
    min-height: 220px;
}

.eh-collapse ::v-deep .ace-editor {
    border: 1px solid #e1e2e3;
    min-height: 120px;
}

.dsv-json-editor {
    margin-bottom: 12px;
}

.dsv-json-editor ::v-deep .ace-editor {
    border: 1px solid #e1e2e3;
    min-height: 120px;
}

::v-deep .ace-editor {
    border: 1px solid #e1e2e3;
}

.footer-button {
    float: right;
    margin-bottom: 12px;
}

.export-ds-list {
    ::v-deep .el-descriptions {
        overflow-x: hidden;
        margin-bottom: 15px;
        padding: 8px;
        background: #f5f7fa;
    }

    ::v-deep .el-descriptions__title {
        font-weight: normal;
    }

    ::v-deep .el-descriptions-item__label {
        width: 36px;
    }
}
.close-btn {
    cursor: pointer;
    color: #f56c6c;
}

.select-item {
    display: flex;
    border: 1px dashed #fff;
    box-sizing: border-box;

    & .close-btn {
        cursor: pointer;
        color: #f56c6c;
    }

    & .el-input + .el-input {
        margin-left: 4px;
    }
}

.select-item + .select-item {
    margin-top: 4px;
}

.select-item.sortable-chosen {
    border: 1px dashed #409eff;
}

.select-line-icon {
    line-height: 32px;
    font-size: 22px;
    padding: 0 4px;
    color: #777;
}
