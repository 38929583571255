@import "@/styles/variables.scss"; 




































































































































































.blank-cell {
  font-style: italic;
  color: #cccccc;

  span.invisible-content {
    opacity: 0;
  }
}
