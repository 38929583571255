@import "@/styles/variables.scss"; 



























































.button-group-container.selected {
  outline: 2px solid $--color-primary !important;
}

