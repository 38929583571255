@import "@/styles/variables.scss"; 














































































































































































































.container-scroll-bar {
  ::v-deep .el-scrollbar__wrap, ::v-deep .el-scrollbar__view {
    overflow-x: hidden;
  }
}

.form-widget-container {
  padding: 13px;
  background: #f1f2f3;

  overflow-x: hidden;
  overflow-y: auto;

  .el-form.full-height-width {
    height: 100%;
    padding: 3px;
    background: #ffffff;

    .no-widget-hint {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 18px;
      color: #999999;
    }

    .form-widget-canvas {
      //min-height: calc(100vh - 56px - 68px);
      min-height:100%;
      padding: 3px;
    }
  }

  /* 隐藏组件拖拽状态中显示的黑点 */
  ::v-deep li.container-widget-item, ::v-deep li.field-widget-item {
    list-style: none;
  }

  .el-form.PC-layout {
    //
  }

  .el-form.Pad-layout {
    margin: 0 auto;
    max-width: 960px;
    border-radius: 15px;
    box-shadow: 0 0 1px 10px #495060;
  }

  .el-form.H5-layout {
    margin: 0 auto;
    width: 420px;
    border-radius: 15px;
    //border-width: 10px;
    box-shadow: 0 0 1px 10px #495060;
  }

  .el-form.widget-form ::v-deep .el-row {
    padding: 2px;
    border: 1px dashed rgba(170, 170, 170, 0.75);
  }
}

.grid-cell {
  min-height: 30px;
  border-right: 1px dotted #cccccc;

}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
