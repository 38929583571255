@import "@/styles/variables.scss"; 












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-input-number.full-width-input,
.el-cascader.full-width-input {
  width: 100% !important;
}

.el-form-item--medium {
  .el-radio {
    line-height: 36px !important;
  }

  .el-rate {
    margin-top: 8px;
  }
}

.el-form-item--small {
  .el-radio {
    line-height: 32px !important;
  }

  .el-rate {
    margin-top: 6px;
  }
}

.el-form-item--mini {
  .el-radio {
    line-height: 28px !important;
  }

  .el-rate {
    margin-top: 4px;
  }
}

.clear-fix:before,
.clear-fix:after {
  display: table;
  content: "";
}

.clear-fix:after {
  clear: both;
}

.float-right {
  float: right;
}
div.table-container {
  table.table-layout {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    td.table-cell {
      display: table-cell;
      height: 36px;
      border: 1px solid #e1e2e3;
    }
  }
}

div.tab-container {
}

.label-left-align ::v-deep .el-form-item__label {
  text-align: left;
}

.label-center-align ::v-deep .el-form-item__label {
  text-align: center;
}

.label-right-align ::v-deep .el-form-item__label {
  text-align: right;
}

.static-content-item {
  min-height: 20px;
  display: flex;
  align-items: center;

  ::v-deep .el-divider--horizontal {
    margin: 0;
  }
}
::v-deep .el-textarea__inner {
  height: 70vh !important;
}
.my-textarea:focus {
  outline: none;
  border-color: #409eff;
}
// .button-margin {
//   margin: 10px;
// }
.tips {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 20px 0;
}
.tipsNo {
  border-left: none;
  margin-top: 0px;
  text-align: left !important;
  font-weight: bolder;
}
.tipsMar {
  padding: 8px 0px 16px 0px;
  font-weight: bolder;
  text-align: left !important;
}
.h80v {
  height: 80vh !important;
}
.myFlex {
  margin-top: 0px;
  padding-top: 0px;
  display: flex;
  line-height: 23px;
  padding-left: 6px;
}
.hljs {
  margin: 10px;
}
.hljs-bootom {
  margin-bottom: 10px;
}
.pborder {
  border: 1px solid #eaeefb;
  margin: 5px;
}
.nopadding {
  padding: 0px !important;
}
