@import "@/styles/variables.scss"; 













































.icon-badge{
    margin-right: 20px;
    /* margin-top: 10px; */
}
.icon-class {
    font-size: 18px;
    cursor: pointer;
    color: var(--color-whitle);
}
.el-badge__content.is-fixed{
    position: absolute;
    top: 0;
    right: calc(1px + var(--el-badge-size)/2);
    transform: translateY(-50%) translateX(100%);
}
