@import "@/styles/variables.scss"; 








.step-three {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    i {
        font-size: 80px;
        color: $base-color-default;
    }
    .tip {
        font-size: 16px;
        color: #000;
        line-height: 28px;
        strong {
            color: $base-color-default;
            cursor: pointer;
        }
    }
}
