@import "@/styles/variables.scss"; 

































































li.col-item {
  list-style: none;
  display: flex;

  span.col-span-title {
    display: inline-block;
    font-size: 13px;
    width: 120px;
  }

  .cell-span-input {
    width: 125px;
  }

  .col-delete-button {
    margin-left: 6px;
  }
}

