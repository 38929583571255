@import "@/styles/variables.scss"; 






























































































































































































































































































































































.markdown-editor-box {
    z-index: 2;
    position: relative;
}
.contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    width: 500px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
    padding: 15px;
    .operate-menu {
        position: relative;
        // &::after {
        //     position: absolute;
        //     content: "";
        //     width: 0;
        //     height: 0;
        //     border-left: 20px solid transparent; /* 左边透明 */
        //     border-right: 20px solid transparent; /* 右边透明 */
        //     border-bottom: 20px solid #f00;
        // }
        width: 100px;
        margin-top: 5px;
        box-shadow: 1px 0px 2px 1px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        padding: 10px;
        li {
            width: 100%;
            margin: 0;
            padding: 7px 16px;
            cursor: pointer;
            list-style: none;
            &:hover {
                background: #eee;
            }
        }
    }

    .chat-box-content {
        background: #f6f8f9;
        padding: 10px;
        line-height: 1.5;
        margin-right: 75px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .chat-loading {
            width: 5px;
            height: 20px;
            background: #000 !important;
            animation: chatBlink 1s infinite;
        }
        @keyframes chatBlink {
            0% {
                opacity: 0;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }
}
