@import "@/styles/variables.scss"; 




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.product-intarget-index{
    .vxe-table--render-default
  .vxe-body--column.col--ellipsis:not(.col--actived)
  > .vxe-cell,
.vxe-table--render-default
  .vxe-footer--column.col--ellipsis:not(.col--actived)
  > .vxe-cell,
.vxe-checkbox .vxe-checkbox--label {
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
  /* word-break: break-all; */
  /* word-wrap: break-word; */
  max-height: 200px;
}
.vxe-table--render-default
  .vxe-body--column.col--ellipsis
  > .vxe-cell
  .vxe-tree-cell {
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
  /* word-break: break-all; */
  /* word-wrap: break-word; */
  max-height: 200px;
  padding-left: 0px;
}
}

