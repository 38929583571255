@import "@/styles/variables.scss"; // cover some element-ui styles

.el-breadcrumb__inner,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}

.el-upload {
  input[type="file"] {
    display: none !important;
  }
}

.el-upload__input {
  display: none;
}

.cell {
  .el-tag {
    margin-right: 0px;
  }
}

.small-padding {
  .cell {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.fixed-width {
  .el-button--mini {
    padding: 7px 10px;
    width: 60px;
  }
}

.status-col {
  .cell {
    padding: 0 10px;
    text-align: center;

    .el-tag {
      margin-right: 0px;
    }
  }
}

// to fixed https://github.com/ElemeFE/element/issues/2461
.el-dialog {
  transform: none;
  left: 0;
  position: relative;
  margin: 0 auto;
}
// refine element ui upload
.upload-container {
  .el-upload {
    width: 100%;

    .el-upload-dragger {
      width: 100%;
      height: 200px;
    }
  }
}

// dropdown
.el-dropdown-menu {
  a {
    display: block
  }
}

// fix date-picker ui bug in filter-item
.el-range-editor.el-input__inner {
  display: inline-flex !important;
}

// to fix el-date-picker css style
.el-range-separator {
  box-sizing: content-box;
}

.el-dialog__header {
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.el-dialog__footer {
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}

//define
.el-checkbox__input.is-disabled + .el-checkbox__label {
    color: #606266 !important;
}

.el-checkbox__input.is-disabled.is-checked + .el-checkbox__label {
    color: #606266 !important;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner:after {
    border-color: #606266 !important;
}

.el-radio__input.is-disabled + span.el-radio__label {
    color: #606266 !important;
}

.el-radio__input.is-disabled.is-checked .el-radio__inner::after {
    background-color: #606266 !important;
}

.el-radio__input.is-disabled .el-radio__inner, .el-radio__input.is-disabled.is-checked .el-radio__inner {
    border-color: #606266 !important;
}

.el-input.is-disabled .el-input__inner, textarea:disabled {
    color: #606266 !important;
}

.el-range-editor.is-disabled input {
    color: #606266 !important;
}
.el-range-editor.is-disabled .el-range-separator {
   color: #606266 !important;
}
.el-textarea.is-disabled .el-textarea__inner {
    color: #606266 !important;
}
.el-select {
	width: 100% !important;
}
.el-input-number{
	width: 100% !important;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100% !important;
}

.el-loading-spinner{
    .el-icon-loading{
        color: #fff;
        font-size: 18px;
    }
    .el-loading-text{
        color: #fff !important;
        font-size: 15px  !important;
    }
}
