@import "@/styles/variables.scss"; 






































































































.el-row,
.gp-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;
}
.qz-login-container {
  height: 100vh;
  background: url("~@/assets/login_images/v2_roexf3_1.jpg") 35% center fixed
    no-repeat;
  background-size: cover;
  position: relative;
  .qz-header-title {
    height: 80px;
    z-index: 1;
    position: relative;
    width: 100%;
    border-radius: 14px 14px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: 20%;
    .qz-title {
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      z-index: 5;
      letter-spacing: 0.1em;
      text-shadow: 0 0 10px #2330a3;
      -webkit-text-shadow: 0 0 10px #2a3591;
      -moz-text-shadow: 0 0 10px #2a3591;
      -o-text-shadow: 0 0 10px #2a3591;
    }
    .qz-title-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 10px 10px 0 0;
    }
    .qz-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      border-radius: 10px 10px 0 0;
      /* background-color: rgba(0, 2, 14, 0.5); */
      background-color: rgba(0, 2, 14, 0);
    }
  }

  .qz-title-tips {
    margin-top: 29px;
    font-size: 26px;
    font-weight: 400;
    color: rgba(14, 18, 26, 1);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .qz-login-btn {
    display: inherit;
    width: 280px;
    height: 42px;
    border-radius: 25px;
    margin-top: 5px;
    border: 0;
    margin-right: auto;
    margin-left: auto;
    letter-spacing: 0.3em;
    font-size: 15px;
    &:hover {
      opacity: 0.9;
    }
  }

  .qz-login-form {
    position: relative;
    max-width: 100%;
    min-width: 300px;
    margin: 0 5vw 5vw;
    overflow: hidden;
    padding-bottom: 4.5vh;
    background-color: #ffffff;
    border-radius: 14px;
    box-shadow: 0 0 5px 5px #434fc5;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    .qz-forget-password {
      width: 100%;
      margin-top: 40px;
      text-align: left;

      .qz-forget-pass {
        width: 129px;
        height: 19px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(92, 102, 240, 1);
      }
    }
    /** 修改tabs下划线样式 */
    ::v-deep {
      .el-tabs__item {
        font-size: 16px;
        &.is-active {
          font-weight: 600;
        }
      }
      .el-tabs__nav-wrap::after {
        background-color: transparent !important;
      }
    }
  }

  .qz-tips {
    margin-bottom: 10px;
    font-size: $base-font-size-default;
    color: $base-color-white;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .qz-title-container {
    position: relative;

    .qz-title {
      margin: 0 auto 40px auto;
      font-size: 34px;
      font-weight: bold;
      color: $base-color-blue;
      text-align: center;
    }
  }

  ::v-deep {
    .el-form-item {
      margin: 15px 0;
      color: #454545;
      background: transparent;
      border: 1px solid transparent;
      border-radius: 2px;
      &__content {
        min-height: $base-input-height;
        line-height: $base-input-height;
      }

      &__error {
        position: absolute;
        top: 100%;
        left: 18px;
        font-size: $base-font-size-small;
        line-height: 18px;
        color: $base-color-red;
      }
    }

    .el-input {
      box-sizing: border-box;

      input {
        height: 58px;
        padding-left: 95px;
        font-size: $base-font-size-default;
        line-height: 58px;
        color: $base-font-color;
        /* background: #f6f4fc; */
        border: 0;
        caret-color: $base-font-color;
        border-bottom: 1px solid #eee;
      }
    }
  }
}
.qz-remember-password {
  margin-top: 20px;
  display: flex;
  margin-left: 10px;
}

.protocol {
  text-decoration: underline;
  color: #49b7fb;
}

.forgot-password {
  font-size: 13px;
  color: #888a88;
  margin-top: 20px;
  text-align: center;
  a {
    text-decoration: underline;
    color: #198afa;
  }
}
