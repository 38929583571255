@css-prefix: x-spreadsheet;
// color
@red-color: #DB2828;
@red-hover-color: #d01919;
@orange-color: #F2711C;
@orange-hover-color: #f26202;
@yellow-color: #FBBD08;
@yellow-hover-color: #eaae00;
@olive-color: #B5CC18;
@olive-hover-color: #a7bd0d;
@green-color: #21BA45;
@green-hover-color: #16ab39;
@teal-color: #00B5AD;
@teal-hover-color: #009c95;
@blue-color: #2185D0;
@blue-hover-color: #1678c2;
@violet-color: #6435C9;
@violet-hover-color: #5829bb;
@purple-color: #A333C8;
@purple-hover-color: #9627ba;
@pink-color: #E03997;
@pink-hover-color: #e61a8d;
@brown-color: #A5673F;
@brown-hover-color: #975b33;
@grey-color: #767676;
@grey-hover-color: #838383;
@dark-color: #343a40;
@dark-hover-color: darken(@dark-color, 10%);
@black-color: #1B1C1D;
@black-hover-color: #27292a;

// base
@border-style: 1px solid #e0e2e4;
@icon-size: 18px;
@line-height: 1.25em;
@border-color: #e9e9e9;
@border: 1px solid @border-color;
@input-border: @border;
@input-padding: 0.5em 0.75em;
@input-box-shadow: inset 0 1px 2px hsla(0,0%,4%,.06);
@border-radius: 2px;
@form-field-height: 30px;
@primary-color: @blue-color;
@primary-hover-color: @blue-hover-color;

// method
.type-primary() {
  color: #fff;
  background-color: @primary-color;
  &:hover, &.active {
    color: #fff;
    background-color: @primary-hover-color;
  }
}

body {
  margin: 0;
}

.@{css-prefix} {
  font-size: 13px;
  line-height: normal;
  user-select: none;
  -moz-user-select: none;
  font-family: 'Lato', 'Source Sans Pro', Roboto, Helvetica, Arial, sans-serif;
  box-sizing: content-box;
  background: #fff;
  -webkit-font-smoothing: antialiased;

  textarea {
    font: 400 13px Arial, 'Lato', 'Source Sans Pro', Roboto, Helvetica, sans-serif;
  }
}

.@{css-prefix}-sheet {
  position: relative;
  overflow: hidden;
}

.@{css-prefix}-table {
  // html5 bottom margin bug
  vertical-align: bottom;
}

.@{css-prefix}-tooltip {
  font-family: inherit;
  position: absolute;
  padding: 5px 10px;
  color: #fff;
  border-radius: 1px;
  background: rgba(0, 0, 0, 1);
  font-size: 12px;
  z-index: 201;

  &:before {
    pointer-events: none;
    position: absolute;
    left: calc(50% - 4px);
    top: -4px;
    content: "";
    width: 8px;
    height: 8px;
    background: inherit;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
    box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, .3);
  }
}

.@{css-prefix}-color-palette {
  padding: 5px;

  table {
    margin: 0;
    padding: 0;
    border-collapse: separate;
    border-spacing: 2;
    background: #fff;

    td {
      margin: 0;
      cursor: pointer;
      border: 1px solid transparent;

      &:hover {
        border-color: #ddd;
      }

      .@{css-prefix}-color-palette-cell {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.@{css-prefix}-border-palette {
  padding: 6px;

  table {
    margin: 0;
    padding: 0;
    border-collapse: separate;
    border-spacing: 0;
    background: #fff;
    table-layout: fixed;

    td {
      margin: 0;
    }
  }

  .@{css-prefix}-border-palette-left {
    border-right: 1px solid #eee;
    padding-right: 6px;

    .@{css-prefix}-border-palette-cell {
      width: 30px;
      height: 30px;
      cursor: pointer;
      text-align: center;

      .@{css-prefix}-icon-img {
        opacity: .8;
      }

      &:hover {
        background-color: #eee;
      }
    }
  }

  .@{css-prefix}-border-palette-right {
    padding-left: 6px;

    .@{css-prefix}-toolbar-btn {
      margin-top: 0;
      margin-bottom: 3px;
    }

    .@{css-prefix}-line-type {
      position: relative;
      left: 0;
      top: -3px;
    }

  }

}

.@{css-prefix}-dropdown {
  position: relative;

  .@{css-prefix}-dropdown-content {
    position: absolute;
    z-index: 200;
    background: #fff;
    box-shadow: 1px 2px 5px 2px rgba(51,51,51,.15);
  }

  &.bottom-left {
    .@{css-prefix}-dropdown-content {
      top: calc(~'100% + 5px');
      left: 0;
    }
  }

  &.bottom-right {
    .@{css-prefix}-dropdown-content {
      top: calc(~'100% + 5px');
      right: 0;
    }
  }

  &.top-left {
    .@{css-prefix}-dropdown-content {
      bottom: calc(~'100% + 5px');
      left: 0;
    }
  }

  &.top-right {
    .@{css-prefix}-dropdown-content {
      bottom: calc(~'100% + 5px');
      right: 0;
    }
  }


  .@{css-prefix}-dropdown-title {
    padding: 0 5px;
    display: inline-block;
  }

  .@{css-prefix}-dropdown-header {
    .@{css-prefix}-icon.arrow-left {
      margin-left: 4px;
    }
    .@{css-prefix}-icon.arrow-right {
      width: 10px;
      margin-right: 4px;
      .arrow-down {
        left: -130px;
      }
    }
  }
}
/* resizer **/
.@{css-prefix}-resizer {
  position: absolute;
  z-index: 11;

  .@{css-prefix}-resizer-hover {
    background-color: rgba(75, 137, 255, .25);
  }
  .@{css-prefix}-resizer-line {
    position: absolute;
  }

  &.horizontal {
    cursor: row-resize;
    .@{css-prefix}-resizer-line {
      border-bottom: 2px dashed rgb(75, 137, 255);
      left: 0;
      bottom: 0;
    }
  }
  &.vertical {
    cursor: col-resize;
    .@{css-prefix}-resizer-line {
      border-right: 2px dashed rgb(75, 137, 255);
      top: 0;
      right: 0;
    }
  }
}
/* scrollbar */
.@{css-prefix}-scrollbar {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #f4f5f8;
  opacity: 0.9;
  z-index: 12;
  &.horizontal {
    right: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    > div {
      height: 1px;
      background: #ddd;
    }
  }
  &.vertical {
    bottom: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    > div {
      width: 1px;
      background: #ddd;
    }
  }
  &:hover {
    // opacity: .85;
  }
}

/* @{css-prefix}-overlayer */
.@{css-prefix}-overlayer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  .@{css-prefix}-overlayer-content {
    position: absolute;
    overflow: hidden;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
}

.@{css-prefix}-editor, .@{css-prefix}-selector {
  box-sizing: content-box;
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* @{css-prefix}-selector */
.@{css-prefix}-selector {
  .hide-input {
    position: absolute;
    z-index: 0;
    input {
      padding: 0;
      width: 0;
      border: none!important;
    }
  }
  .@{css-prefix}-selector-area {
    position: absolute;
    border: 2px solid rgb(75, 137, 255);
    background: rgba(75, 137, 255, .1);
    z-index: 5;
  }
  .@{css-prefix}-selector-clipboard, .@{css-prefix}-selector-autofill {
    position: absolute;
    background: transparent;
    z-index: 100;
  }
  .@{css-prefix}-selector-clipboard {
    border: 2px dashed rgb(75, 137, 255);
  }
  .@{css-prefix}-selector-autofill {
    border: 1px dashed rgba(0, 0, 0, .45); // #606060; // rgba(0, 0, 0, .2);
  }
  .@{css-prefix}-selector-corner {
    pointer-events: auto;
    position: absolute;
    cursor: crosshair;
    font-size: 0;
    height: 5px;
    width: 5px;
    right: -5px;
    bottom: -5px;
    border: 2px solid rgb(255, 255, 255);
    background: rgb(75, 137, 255);
  }
}
.@{css-prefix}-editor {
  z-index: 20;
  .@{css-prefix}-editor-area {
    position: absolute;
    text-align: left;
    border: 2px solid rgb(75, 137, 255);
    line-height: 0;
    z-index: 100;
    pointer-events: auto;

    textarea {
      box-sizing: content-box;
      border: none;
      padding: 0 3px;
      outline: none;
      resize: none;
      text-align: start;
      overflow-y: hidden;
      font: 400 13px Arial, 'Lato', 'Source Sans Pro', Roboto, Helvetica, sans-serif;
      color: inherit;
      white-space: normal;
      word-wrap: break-word;
      line-height: 22px;
      margin: 0;
    }

    .textline {
      overflow: hidden;
      visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
    }
  }
}

.@{css-prefix}-item {
  user-select: none;
  background: 0;
  border: 1px solid transparent;
  outline: none;
  height: 26px;
  color: rgba(0, 0, 0, .9);
  line-height: 26px;
  list-style: none;
  padding: 2px 10px;
  cursor: default;
  text-align: left;
  overflow: hidden;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover, &.active {
    background: rgba(0, 0, 0, .05);
  }

  // &.active {
    ////   background: #89aef53d;
  // }

  &.divider {
    height: 0;
    padding: 0;
    margin: 5px 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  .label {
    float: right;
    opacity: .65;
    font-size: 1em;
  }
}

.x-spreadsheet-item,
.x-spreadsheet-header,
{

  &.state {
    padding-left: 35px!important;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 12px;
      top: calc(50% - 5px);
      background: rgba(0, 0, 0, 0.08);
      // border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }
  }
  &.state.checked:before {
    background: #4b89ff;
    // content: '';
    // position: absolute;
    // width: 5px;
    // height: 12px;
    // color: #4b89ff; // #353A41;
    // border-left: none;
    // border-top: none;
    // border-bottom: 2px solid;
    // border-right: 2px solid;
    // left: 15px;
    // top: 6px;
    // background: none;
    // border-radius: 0;
    // transform-origin: center;
    // transform: rotate(45deg);
    // -webkit-transform: rotate(45deg);
  }

}

.@{css-prefix}-checkbox {
  position: relative;
  display: inline-block;
  backface-visibility: hidden;
  outline: 0;
  vertical-align: baseline;
  font-style: normal;
  font-size: 1rem;
  line-height: 1em;
  > input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0!important;
    outline: 0;
    z-index: -1;
  }
}

.@{css-prefix}-suggest,
.@{css-prefix}-contextmenu,
.@{css-prefix}-sort-filter {
  position: absolute;
  box-shadow: 1px 2px 5px 2px rgba(51, 51, 51, 0.15);
  background: #fff;
  z-index: 100;
  width: 260px;
  pointer-events: auto;
  overflow: auto;
}

.@{css-prefix}-suggest {
  width: 200px;
}

.@{css-prefix}-filter {
  border: 1px solid #e9e9e9;
  font-size: 12px;
  margin: 10px;

  .@{css-prefix}-header {
    padding: .5em .75em;
    background: #f8f8f9;
    border-bottom: 1px solid #e9e9e9;
    border-left: 1px solid transparent;
  }
  .@{css-prefix}-body {
    height: 200px;
    overflow-y: auto;

    .@{css-prefix}-item {
      height: 20px;
      line-height: 20px;
    }
  }
}

.@{css-prefix}-sort-filter {
  .@{css-prefix}-buttons {
    margin: 10px;
  }
}

.@{css-prefix}-toolbar, .@{css-prefix}-bottombar {
  height: 40px;
  padding: 0 30px;
  text-align: left;
  background: #f5f6f7;
  display: flex;
}

.@{css-prefix}-bottombar {
  position: relative;
  border-top: 1px solid #e0e2e4;

  .@{css-prefix}-menu > li {
    line-height: 40px;
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: middle;
    border-right: 1px solid #e8eaed;
  }
}

.@{css-prefix}-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  user-select: none;
}
.@{css-prefix}-menu > li {
  float: left;
  line-height: 1.25em;
  padding: .785em 1em;
  margin: 0;
  vertical-align: middle;
  text-align: left;
  font-weight: 400;
  color: #80868b;
  white-space: nowrap;
  cursor: pointer;
  transition: all .3s;
  font-weight: bold;

  &.active {
    background-color: #fff;
    color: rgba(0, 0, 0, .65);
  }

  .@{css-prefix}-icon {
    margin: 0 6px;

    .@{css-prefix}-icon-img {
      &:hover {
        opacity: .85;
      }
    }
  }

  .@{css-prefix}-dropdown {
    display: inline-block;
  }
}

.@{css-prefix}-toolbar {
  border-bottom: 1px solid #e0e2e4;

  .@{css-prefix}-toolbar-btns {
    display: inline-flex;
  }

  .@{css-prefix}-toolbar-more {
    padding: 0 6px 6px;
    text-align: left;
    .@{css-prefix}-toolbar-divider {
      margin-top: 0;
    }
  }

  .@{css-prefix}-toolbar-btn {
    flex: 0 0 auto;
    display: inline-block;
    border: 1px solid transparent;
    height: 26px;
    line-height: 26px;
    min-width: 26px;
    margin: 6px 1px 0;
    padding: 0;
    text-align: center;
    border-radius: 2px;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:hover, &.active {
      background: rgba(0, 0, 0, .08);
    }
  }
}

.@{css-prefix}-toolbar-divider {
  display: inline-block;
  border-right: 1px solid #e0e2e4;
  width: 0;
  vertical-align: middle;
  height: 18px;
  margin: 12px 3px 0;
}

.@{css-prefix}-print {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.@{css-prefix}-print-bar {
  background: #424242;
  height: 60px;
  line-height: 60px;
  padding: 0 30px;

  .-title {
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    float: left;
  }

  .-right {
    float: right;
    margin-top: 12px;
  }
}
.@{css-prefix}-print-content {
  display: flex;
  flex: auto;
  flex-direction: row;
  background: #d0d0d0;
  height: calc(100% - 60px);

  .-sider {
    flex: 0 0 300px;
    width: 300px;
    border-left: 2px solid #ccc;
    background: #fff;
  }

  .-content {
    flex: auto;
    overflow-x: auto;
    overflow-y: scroll;
    height: 100%;
  }
}


.@{css-prefix}-canvas-card-wraper {
  /*margin: 40px 20px;*/
}
.@{css-prefix}-canvas-card {
  background: #fff;
  margin: 40px auto;
  page-break-before: auto;
  page-break-after: always;
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 3px rgba(0,0,0,0.12), 0 4px 5px 0 rgba(0,0,0,0.20);
}

.@{css-prefix}-calendar {
  color: rgba(0,0,0,.65);
  background: #ffffff;
  user-select: none;

  .calendar-header {
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    width: 100%;
    float: left;
    background: #f9fafb;

    .calendar-header-left {
      padding-left: 5px;
      float: left;
    }

    .calendar-header-right {
      float: right;

      a {
        padding: 3px 0;
        margin-right: 2px;
        border-radius: 2px;
      }
      a:hover {
        background: rgba(0,0,0,.08);
      }
    }
  }

  .calendar-body {
    border-collapse: collapse;
    border-spacing: 0;

    th, td {
      width: 100%/7;
      min-width: 32px;
      text-align: center;
      font-weight: 700;
      line-height: 30px;
      padding: 0;
    }

    td > .cell {
      // border: 1px solid rgba(0,0,0,.05);

      &:hover {
        background: #ecf6fd;
      }

      &.active, &.active:hover {
        background: #ecf6fd;
        color: #2185D0;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}

.@{css-prefix}-datepicker {
  box-shadow: 2px 2px 5px rgba(0,0,0,.2);
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  z-index: 10;
  width: auto;
}

.@{css-prefix}-buttons {
  display: flex;
  justify-content: flex-end;

  .@{css-prefix}-button {
    margin-left: 8px;
  }
}
.@{css-prefix}-button {
  display: inline-block;
  border-radius: 3px;
  line-height: 1em;
  min-height: 1em;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  padding: .75em 1em;
  color: rgba(0,0,0,.6);
  background: #E0E1E2;
  text-decoration: none;
  font-family: "Lato","proxima-nova","Helvetica Neue",Arial,sans-serif;
  //box-shadow: 0px 1px 2px -1px rgba(255,255,255,0.5) inset, 0px -2px 0px 0px rgba(0,0,0,0.1) inset;
  //box-shadow: 0 0 0 0 rgba(0,0,0,.5) inset;
  outline: none;
  vertical-align: baseline;
  zoom: 1;
  user-select: none;
  transition: all 0.1s linear;

  &.active, &:hover {
    background-color: #C0C1C2;
    color: rgba(0,0,0,.8);
  }

  &.primary {
    .type-primary;
  }
}

// form begin
.@{css-prefix}-form-input {
  font-size: 1em;
  position: relative;
  font-weight: 400;
  display: inline-flex;
  color: rgba(0,0,0,.87);

  input {
    z-index: 1;
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: @form-field-height;
    height: @form-field-height;
    padding: 0 8px;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    transition: box-shadow .1s ease,border-color .1s ease;
    box-shadow: inset 0 1px 2px hsla(0,0%,4%,.06);

    &:focus {
      border-color: rgb(75, 137, 255);
      box-shadow: inset 0 1px 2px rgba(75, 137, 255, .2);
    }
  }
}

.@{css-prefix}-form-select {
  position: relative;
  display: inline-block;
  background: #fff;
  border: @input-border;
  border-radius: 2px;
  cursor: pointer;
  color: rgba(0,0,0,.87);
  user-select: none;
  box-shadow: inset 0 1px 2px hsla(0,0%,4%,.06);

  .input-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 60px;
    width: auto;
    height: @form-field-height;
    line-height: @form-field-height;
    padding: 0 8px;
  }
}
.@{css-prefix}-form-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .@{css-prefix}-form-field {
    flex: 0 1 auto;
    .label {
      display: inline-block;
      margin: 0 10px 0 0;
    }
  }
}
.@{css-prefix}-form-field {
  display: block;
  vertical-align: middle;
  margin-left: 10px;
  margin-bottom: 10px;

  &:first-child {
    margin-left: 0;
  }

  &.error {
    .@{css-prefix}-form-select, input {
      border-color: #f04134;
    }
  }

  .tip {
    color: #f04134;
    font-size: .9em;
  }
}
// form end
.@{css-prefix}-dimmer {
  display: none;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  transition: background-color 0.5s linear;
  user-select: none;
  z-index: 1000;

  &.active {
    display: block;
    opacity: 1;
  }
}

form fieldset {
  border: none;

  label {
    display: block;
    margin-bottom: .5em;
    font-size: 1em;
    color: #666;
  }

  select {
    font-size: 1.1em;
    width: 90%;
    background-color: #fff;
    border: none;
    border-bottom: 2px solid #ddd;
    padding: .5em .85em;
    border-radius: 2px;
  }
}

.@{css-prefix}-modal, .@{css-prefix}-toast {
  font-size: 13px;
  position: fixed;
  z-index: 1001;
  text-align: left;
  line-height: @line-height;
  min-width: 360px;
  color: rgba(0,0,0,.87);
  font-family: 'Lato', 'Source Sans Pro', Roboto, Helvetica, Arial, sans-serif;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,.1);
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
}

.@{css-prefix}-toast {
  background-color: rgba(255,255,255,.85);
}

.@{css-prefix}-modal-header, .@{css-prefix}-toast-header {
  font-weight: 600;
  background-clip: padding-box;
  background-color: rgba(255,255,255,.85);
  border-bottom: 1px solid rgba(0,0,0,.05);
  border-radius: 4px 4px 0 0;

  .@{css-prefix}-icon {
    position: absolute;
    right: .8em;
    top: .65em;
    border-radius: 18px;

    &:hover {
      opacity: 1;
      background: rgba(0, 0, 0, 0.08);
    }
  }
}

.@{css-prefix}-toast-header {
  color: @orange-color;
}

.@{css-prefix}-modal-header {
  border-bottom: @border-style;
  background: rgba(0, 0, 0, .08);
  font-size: 1.0785em;
}

.@{css-prefix}-modal-header,
.@{css-prefix}-modal-content,
.@{css-prefix}-toast-header,
.@{css-prefix}-toast-content {
  padding: .75em 1em;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .@{css-prefix}-toolbar {
    display: none;
  }
}

.@{css-prefix}-icon {
  width: 18px;
  height: 18px;
  margin: 1px 1px 2px 1px;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  overflow: hidden;
  position: relative;
  display: inline-block;

  .@{css-prefix}-icon-img {
    background-image: url('../assets/sprite.svg');
    position: absolute;
    width: 262px;
    height: 444px;
    opacity: 0.56;

    &.undo {
      left: 0;
      top: 0;
    }
    &.redo {
      left: -1 * @icon-size;
      top: 0;
    }
    &.print {
      left: -2 * @icon-size;
      top: 0;
    }
    &.paintformat {
      left: -3 * @icon-size;
      top: 0;
    }
    &.clearformat {
      left: -4 * @icon-size;
      top: 0;
    }
    &.font-bold {
      left: -5 * @icon-size;
      top: 0;
    }
    &.font-italic {
      left: -6 * @icon-size;
      top: 0;
    }
    &.underline {
      left: -7 * @icon-size;
      top: 0;
    }
    &.strike {
      left: -8 * @icon-size;
      top: 0;
    }
    &.color {
      left: -9 * @icon-size;
      top: 0;
    }
    &.bgcolor {
      left: -10 * @icon-size;
      top: 0;
    }
    &.merge {
      left: -11 * @icon-size;
      top: 0;
    }
    &.align-left {
      left: -12 * @icon-size;
      top: 0;
    }
    &.align-center {
      left: -13 * @icon-size;
      top: 0;
    }
    &.align-right {
      left: 0;
      top: -1 * @icon-size;
    }
    &.align-top {
      left: -1 * @icon-size;
      top: -1 * @icon-size;
    }
    &.align-middle {
      left: -2 * @icon-size;
      top: -1 * @icon-size;
    }
    &.align-bottom {
      left: -3 * @icon-size;
      top: -1 * @icon-size;
    }
    &.textwrap {
      left: -4 * @icon-size;
      top: -1 * @icon-size;
    }
    &.autofilter {
      left: -5 * @icon-size;
      top: -1 * @icon-size;
    }
    &.formula {
      left: -6 * @icon-size;
      top: -1 * @icon-size;
    }
    &.arrow-down {
      left: -7 * @icon-size;
      top: -1 * @icon-size;
    }
    &.arrow-right {
      left: -8 * @icon-size;
      top: -1 * @icon-size;
    }
    &.link {
      left: -9 * @icon-size;
      top: -1 * @icon-size;
    }
    &.chart {
      left: -10 * @icon-size;
      top: -1 * @icon-size;
    }
    &.freeze {
      left: -11 * @icon-size;
      top: -1 * @icon-size;
    }
    &.ellipsis {
      left: -12 * @icon-size;
      top: -1 * @icon-size;
    }
    &.add {
      left: -13 * @icon-size;
      top: -1 * @icon-size;
    }
    &.border-all {
      left: 0;
      top: -2 * @icon-size;
    }
    &.border-inside {
      left: -1 * @icon-size;
      top: -2 * @icon-size;
    }
    &.border-horizontal {
      left: -2 * @icon-size;
      top: -2 * @icon-size;
    }
    &.border-vertical {
      left: -3 * @icon-size;
      top: -2 * @icon-size;
    }
    &.border-outside {
      left: -4 * @icon-size;
      top: -2 * @icon-size;
    }
    &.border-left {
      left: -5 * @icon-size;
      top: -2 * @icon-size;
    }
    &.border-top {
      left: -6 * @icon-size;
      top: -2 * @icon-size;
    }
    &.border-right {
      left: -7 * @icon-size;
      top: -2 * @icon-size;
    }
    &.border-bottom {
      left: -8 * @icon-size;
      top: -2 * @icon-size;
    }
    &.border-none {
      left: -9 * @icon-size;
      top: -2 * @icon-size;
    }
    &.line-color {
      left: -10 * @icon-size;
      top: -2 * @icon-size;
    }
    &.line-type {
      left: -11 * @icon-size;
      top: -2 * @icon-size;
    }
    &.close {
      left: -13 * @icon-size;
      top: -2 * @icon-size;
    }
    &.chevron-down {
      left: 0;
      top: -3 * @icon-size;
    }
    &.chevron-up {
      left: -1 * @icon-size;
      top: -3 * @icon-size;
    }
    &.chevron-left {
      left: -2 * @icon-size;
      top: -3 * @icon-size;
    }
    &.chevron-right {
      left: -3 * @icon-size;
      top: -3 * @icon-size;
    }
  }

  .@{css-prefix}-icon-img {
    &.save {
      background-image: url('../assets/floppy-disk.svg');
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: center;
      width: @icon-size;
      height: @icon-size;
      opacity: 0.56;
    }
    &.preview {
        background-image: url('../assets/eye.svg');
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: center;
        width: @icon-size;
        height: @icon-size;
        opacity: 0.56;
    }
    &.export {
        background-image: url('../assets/download3.svg');
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: center;
        width: @icon-size;
        height: @icon-size;
        opacity: 0.56;
    }
    &.import {
        background-image: url('../assets/file-excel.svg');
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: center;
        width: @icon-size;
        height: @icon-size;
        opacity: 0.56;
    }
    &.image {
      background-image: url('../assets/image.svg');
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: center;
      width: @icon-size;
      height: @icon-size;
      opacity: 0.56;
    }
  }
}
