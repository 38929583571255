@import "@/styles/variables.scss"; 








































































































































































































































































































































































































































































































































































@import "../../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//

.full-width-input {
    width: 100% !important;
}

.hideUploadDiv {
    ::v-deep div.el-upload--picture-card {
        /* 隐藏最后的图片上传按钮 */
        display: none;
    }

    ::v-deep div.el-upload--text {
        /* 隐藏最后的文件上传按钮 */
        display: none;
    }

    ::v-deep div.el-upload__tip {
        /* 隐藏最后的文件上传按钮提示 */
        display: none;
    }
}
