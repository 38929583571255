.flow-container {
  display: inline-block;
  background: #f5f5f7;
  padding: 20px;
  width: 100%;
  height: calc(100vh - 69px);
  box-sizing: border-box;
  text-align: center;
  overflow: auto;
}
.scale-slider {
  position: fixed;
  right: 0;
  z-index: 99;
  padding-right: 10px;
}
.scale-slider .btn {
  display: inline-block;
  padding: 4px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  background: #fff;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}
/*# sourceMappingURL=src/components/form/Process/index.css.map */