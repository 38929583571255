@import "@/styles/variables.scss"; 



















































































































































































































































































































































































































































.sub-form-container {
  margin-bottom: 8px;
  text-align: left; //IE浏览器强制居左对齐

  ::v-deep .el-row.header-row {
    padding: 0;
    display: flex;
  }

  ::v-deep .el-row.sub-form-row {
    padding: 0;
    display: flex;

    .row-number-span {
      margin-left: 16px;
    }
  }
}

div.action-header-column {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  border: 1px solid #e1e2e3;
  background: #f1f2f3;
  padding: 8px;

  .action-label {
    margin-right: 12px;
  }

  .action-button {
    padding-left: 8px;
    padding-right: 8px;
  }
}

div.row-no-header-column {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  text-align: center;
  border: 1px solid #e1e2e3;
  background: #f1f2f3;
}

div.field-header-column {
  display: flex;
  align-items: center;
  justify-content: center;
  //overflow: hidden;
  //white-space: nowrap;  //文字超出长度不自动换行
  //text-overflow: ellipsis;  //文字超出长度显示省略号
  border: 1px solid #e1e2e3;
  background: #f1f2f3;
  padding: 8px;

  span.custom-label i {
    margin: 0 3px;
  }
}

div.field-header-column.is-required:before {
  content: '*';
  color: #F56C6C;
  margin-right: 4px;
}

div.label-center-left {
  text-align: left;
}

div.label-center-align {
  text-align: center;
}

div.label-right-align {
  text-align: right;
}

div.sub-form-action-column {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  border: 1px solid #e1e2e3;
  padding: 8px;

  ::v-deep .el-form-item {
    margin-bottom: 0;
  }

  ::v-deep .el-button {
    font-size: 18px;
    padding: 0;
    background: #DCDFE6;
    border: 4px solid #DCDFE6;
  }

}

div.sub-form-action-column.hide-label {
  ::v-deep .el-form-item__label {
    display: none;
  }
}

div.row-no-column {
  display: flex;
  align-items: center;
  width: 50px;
  border: 1px solid #e1e2e3;
}

div.sub-form-table-column {
  display: inline-block;
  border: 1px solid #e1e2e3;
  padding: 8px;

  ::v-deep .el-form-item {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
  }

  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
}

div.sub-form-table-column.hide-label {
  ::v-deep .el-form-item__label {
    display: none;
  }
}

