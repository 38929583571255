@import "@/styles/variables.scss"; 

















































































.qz-svg-container {
  position: absolute;
  top: 14px;
  left: 5px;
  z-index: $base-z-index;
  font-size: 16px;
  color: #808080;
  cursor: pointer;
  user-select: none;
}

.qz-show-password {
  position: absolute;
  top: 14px;
  right: 25px;
  font-size: 16px;
  color: #808080;
  cursor: pointer;
  user-select: none;
}

.qz-remember-password {
  margin-bottom: 20px;
  display: flex;
  margin-left: 10px;
}

.send-code {
  position: absolute;
  right: 0;
  top: 14px;
  cursor: pointer;
  background-color: #d9ebfa;
  text-align: center;
  border-radius: 20px;
  color: #088af5;
  width: 95px;
  &.sending {
    background-color: #e4e3e3;
    color: #515151;
    cursor: no-drop;
  }
}
