@import "@/styles/variables.scss"; 


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.show-pos-btns {
    margin: -5px;
    .btns-item {
        margin: 5px;
    }
    .mr-5 {
        margin-right: 5px;
    }
}
