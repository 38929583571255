@import "@/styles/variables.scss"; 













































































































.sub-form-container {
  //width: 100%;
  padding: 8px;
  border: 1px dashed #336699;

  ::v-deep .sub-form-table {
    min-height: 68px;

    div.sub-form-table-column {
      display: inline-block;
      //width: 200px;
    }
  }

  ::v-deep .ghost{
    content: '';
    font-size: 0;
    //height: 3px;
    height: 74px;
    width: 1px;
    box-sizing: border-box;
    display: inline-block;
    background: $--color-primary;
    border: 2px solid $--color-primary;
    outline-width: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
}

.sub-form-container.selected {
  outline: 2px solid $--color-primary !important;
}

