body,
html {
  margin: 0;
  padding: 0;
  background: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}
input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}
.editor-tabs {
  background: #121315;
}
.editor-tabs .el-tabs__header {
  margin: 0;
  border-bottom-color: #121315;
}
.editor-tabs .el-tabs__header .el-tabs__nav {
  border-color: #121315;
}
.editor-tabs .el-tabs__item {
  height: 32px;
  line-height: 32px;
  color: #888a8e;
  border-left: 1px solid #121315 !important;
  background: #363636;
  margin-right: 5px;
  user-select: none;
}
.editor-tabs .el-tabs__item.is-active {
  background: #1e1e1e;
  border-bottom-color: #1e1e1e !important;
  color: #fff;
}
.editor-tabs .el-icon-edit {
  color: #f1fa8c;
}
.editor-tabs .el-icon-document {
  color: #a95812;
}
.right-scrollbar .el-scrollbar__view {
  padding: 12px 18px 15px 15px;
}
.el-scrollbar__wrap {
  box-sizing: border-box;
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}
.center-tabs .el-tabs__header {
  margin-bottom: 0 !important;
}
.center-tabs .el-tabs__item {
  width: 50%;
  text-align: center;
}
.center-tabs .el-tabs__nav {
  width: 100%;
}
.reg-item,
.dst-item {
  padding: 6px;
  background: #f8f8f8;
  position: relative;
  border-radius: 4px;
}
.reg-item .close-btn,
.dst-item .close-btn {
  position: absolute;
  right: -3px;
  top: -3px;
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: rgba(0,0,0,0.2);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  font-size: 12px;
}
.reg-item .close-btn:hover,
.dst-item .close-btn:hover {
  background: rgba(210,23,23,0.5);
}
.reg-item + .reg-item,
.dst-item + .reg-item {
  margin-top: 18px;
}
.reg-item + .dst-item,
.dst-item + .dst-item {
  margin-top: 5px;
}
.action-bar .el-button+.el-button {
  margin-left: 15px;
}
.action-bar i {
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.custom-tree-node {
  width: 100%;
  font-size: 14px;
}
.custom-tree-node .node-operation {
  float: right;
}
.custom-tree-node i[class*="el-icon"] + i[class*="el-icon"] {
  margin-left: 6px;
}
.custom-tree-node .el-icon-plus {
  color: #409eff;
}
.custom-tree-node .el-icon-delete {
  color: #157a0c;
}
.el-scrollbar__view {
  overflow-x: hidden;
}
.el-rate {
  display: inline-block;
  vertical-align: text-top;
}
.el-upload__tip {
  line-height: 1.2;
}
.calc-dialog .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
}
.dst-item .el-tag {
  margin-left: 10px;
  float: right;
  margin-top: -5px;
  margin-right: 8px;
  cursor: pointer;
}
/deep/.el-divider--horizontal {
  margin: 30px 0 20px 0;
}
.dstrow {
  padding: 6px;
}
/*# sourceMappingURL=src/components/form/DynamicForm/RightPanel.css.map */