@import "@/styles/variables.scss"; 

































































































@import "../../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//

.readonly-mode-field {
  display: inline-block;
  white-space: pre-wrap;
  line-height: 1.5;
}
