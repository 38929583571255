@import "@/styles/variables.scss"; 




















































































@import "../../../../styles/global.scss"; //* static-content-wrapper已引入，还需要重复引入吗？ *//

.hide-text-span ::v-deep span {
  display: none;
}
