.node-wrap-box {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.node-wrap-box.condition {
  padding: 30px 50px 0;
}
.node-wrap-box.empty {
  overflow: hidden;
}
.node-wrap-box.approver::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 4px;
  border-style: solid;
  border-width: 8px 6px 4px;
  border-color: #cacaca transparent transparent;
  background: #f5f5f7;
}
.node-wrap-box.error.condition .error-tip {
  right: 0;
}
.node-wrap-box.error .error-tip {
  right: -40px;
}
.node-wrap-box.error .flow-path-card {
  border: 1px solid #f00;
}
.node-wrap-box.error .flow-path-card:hover {
  border-width: 0;
}
.node-wrap-box .error-tip {
  position: absolute;
  right: 0;
  top: 15%;
  width: 30px;
  height: 30px;
  color: #f00;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid;
  line-height: 30px;
  transition: right 0.5s;
}
.node-wrap-box.condition .error-tip {
  right: 60px;
}
.end-node {
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.end-node::before {
  content: "";
  width: 10px;
  height: 10px;
  margin: auto;
  border: none;
  margin-bottom: 12px;
  border-radius: 50%;
  background: #dbdcdc;
}
.flow-path-card {
  width: 220px;
  min-height: 82px;
  font-size: 12px;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
  background: #fff;
}
.flow-path-card.current-step {
  border: 2px solid #f00;
}
.flow-path-card:hover {
  box-shadow: 0 0 0 2px #3296fa, 0 0 5px 4px rgba(0,0,0,0.2);
}
.flow-path-card.copy .header {
  background-color: #3296fa;
}
.flow-path-card.approver:hover {
  box-shadow: 0 0 0 2px #ff9431, 0 0 5px 4px rgba(0,0,0,0.2);
}
.flow-path-card.approver .header {
  background-color: #ff9431;
}
.flow-path-card.start-node:hover {
  box-shadow: 0 0 0 2px #576a95, 0 0 5px 4px rgba(0,0,0,0.2);
}
.flow-path-card.start-node .header {
  background-color: #576a95;
}
.flow-path-card .header {
  padding-left: 16px;
  padding-right: 30px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  color: #fff;
  position: relative;
  box-sizing: border-box;
}
.flow-path-card .header .title-box {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flow-path-card .header .title-input {
  position: absolute;
  left: 0;
  border: none;
  background: inherit;
  color: inherit;
  opacity: 0;
}
.flow-path-card .header .title-input:focus {
  border-radius: 6px;
  font-size: 12px;
  padding: 2px;
  padding-left: 4px;
  width: 97%;
  margin-left: 1px;
  height: 18px;
  box-sizing: border-box;
  box-shadow: 0 0 2px 2px #7ec3e1;
  background-color: #f5f5f7;
  color: #000;
  opacity: 1;
}
.flow-path-card .header .title-text {
  vertical-align: middle;
}
.flow-path-card .header > .actions {
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
}
.flow-path-card:not(.start-node):hover .actions {
  visibility: visible;
}
.flow-path-card:not(.start-node):hover .title-text {
  border-bottom: 1px dashed currentColor;
}
.flow-path-card.view-node:hover .title-text,
.flow-path-card.view-condition:hover .title-text {
  border-bottom: none;
}
.flow-path-card .body {
  position: relative;
  padding: 12px;
  padding-right: 30px;
  box-sizing: border-box;
}
.flow-path-card .body .text {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.flow-path-card .icon-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 14px;
  box-sizing: border-box;
}
.flow-path-card .icon-wrapper.left {
  left: 0;
}
.flow-path-card .icon-wrapper.right {
  right: 0;
}
.flow-path-card .icon-wrapper > .right-arrow,
.flow-path-card .icon-wrapper > .left-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.flow-path-card.condition {
  padding: 8px 14px;
}
.flow-path-card.condition .header {
  height: 18px;
  line-height: 18px;
  color: inherit;
  padding: 0;
}
.flow-path-card.condition .header .title-text {
  color: #15bc83;
}
.flow-path-card.condition .body {
  padding: 8px 14px;
}
.flow-path-card.condition .icon-wrapper:hover {
  background-color: #f1f1f1;
}
.flow-path-card.condition .right-arrow,
.flow-path-card.condition .left-arrow {
  visibility: hidden;
}
.flow-path-card.condition:not(.view-condition):hover .right-arrow,
.flow-path-card.condition:not(.view-condition):hover .left-arrow {
  visibility: visible;
}
.flow-path-card.condition:not(.view-condition):hover .priority {
  display: none;
}
.col-box:first-of-type .condition .left {
  display: none;
}
.col-box:last-of-type .condition .right {
  display: none;
}
.add-node-btn-box {
  width: 220px;
  height: 100px;
  position: relative;
  padding-top: 30px;
  margin: auto;
}
.add-node-btn-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  margin: auto;
  width: 2px;
  height: 100%;
  background-color: #cacaca;
}
.add-node-btn-box .add-node-btn {
  display: flex;
  justify-content: center;
  z-index: 2;
}
.add-node-btn-box .add-node-btn .btn {
  width: 32px;
  height: 32px;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  background-color: #3296fa;
  border-color: transparent;
  transition: transform 0.5s;
}
.add-node-btn-box .add-node-btn .btn:hover {
  transform: scale(1.2);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
}
.add-node-btn-box .add-node-btn .btn .icon {
  color: #fff;
}
.branch-wrap {
  margin-top: -12px /*jpgfast add*/;
}
.branch-wrap .branch-box-wrap {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.branch-wrap .branch-box {
  align-items: stretch;
  border-bottom: 2px solid #cacaca;
  border-top: 2px solid #cacaca;
  box-sizing: border-box;
  background: #f5f5f7;
}
.branch-wrap .branch-box > .col-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.branch-wrap .branch-box > .col-box:first-of-type::before,
.branch-wrap .branch-box > .col-box:first-of-type::after {
  content: "";
  position: absolute;
  left: 0;
  height: 3px;
  width: calc(50% - 1px);
  background: #f5f5f7;
}
.branch-wrap .branch-box > .col-box:first-of-type::before {
  top: -2px;
}
.branch-wrap .branch-box > .col-box:first-of-type::after {
  bottom: -2px;
}
.branch-wrap .branch-box > .col-box:last-of-type::before,
.branch-wrap .branch-box > .col-box:last-of-type::after {
  content: "";
  position: absolute;
  right: 0;
  height: 3px;
  width: calc(50% - 1px);
  background: #f5f5f7;
}
.branch-wrap .branch-box > .col-box:last-of-type::before {
  top: -2px;
}
.branch-wrap .branch-box > .col-box:last-of-type::after {
  bottom: -2px;
}
.branch-wrap .branch-box > .col-box .center-line {
  height: 100%;
  width: 2px;
  background: #cacaca;
  position: absolute;
}
.branch-wrap .branch-box > .btn {
  z-index: 99;
  cursor: pointer;
  position: absolute;
  top: 0;
  outline: none;
  transform: translateY(-50%);
  padding: 6px 10px;
  border: none;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0 0 10px 0px rgba(0,0,0,0.2);
  transition: transform 0.3s;
}
.branch-wrap .branch-box > .btn:hover {
  transform: scale(1.1) translateY(-50%);
}
.condition-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
}
.condition-box >div:nth-child(1) .iconfont {
  color: #ff943e;
}
.condition-box >div:nth-child(2) .iconfont {
  color: #3296fa;
}
.condition-box .condition-icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  box-sizing: border-box;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 4px;
}
.condition-box .condition-icon .iconfont {
  font-size: 32px;
}
.condition-box .condition-icon:hover {
  background: #3296fa;
  box-shadow: 0 0 8px 4px rgba(0,0,0,0.1);
}
.condition-box .condition-icon:hover > .iconfont {
  color: #fff;
}
.relative {
  position: relative;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.icon {
  vertical-align: middle;
  width: 14px;
  height: 14px;
  font-size: 14px;
}
.priority {
  position: absolute;
  right: 0;
  font-size: 12px;
}
/*# sourceMappingURL=src/components/jpgflow/Process/FlowCard/index.css.map */