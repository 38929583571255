@import "@/styles/variables.scss"; 































































































































































































































































































































































































































.container{
    background-color: $base-container-color;
    ::v-deep {
        .el-card__header {
            border-bottom: unset;
        }
        .card-1 {
            .el-card__body {
                padding: 0;
                .echarts {
                    width: 100%;
                    height: 260px;
                }
            }
        }
        .card-2 {
            .el-card__body {
                padding: 0;
                .echarts {
                    width: 100%;
                    height: 380px;
                }
            }
        }

        .card-content-2{
            height: 380px;
            padding: 0 20px;
            box-sizing: border-box;
            
        }

        .el-link{
            &.primary{
                color: $--color-primary;
            }
            &.warning{
                color: $--color-warning;
            }
            &.danger{
                color: $--color-danger;
            }
            &.success{
                color: $--color-success;
            }
        }
    }
    
}
