@import "@/styles/variables.scss"; 




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.form-type-table {
    background: #fff;
    position: absolute;
    bottom: -10px;
    padding: 15px;
    border-radius: 10px;
    tr {
        border: 1px solid #999;
    }
    td {
        border: 1px solid #999;
        width: 14px;
        height: 14px;
        &:hover{
            background: #999;
        }
    }
}
