@import "@/styles/variables.scss"; 





























































































































































































































































































































































































































































































































.tabs-bar-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: $base-tabs-bar-height;
    padding-right: $base-padding;
    padding-left: $base-padding;
    user-select: none;
    background: $base-color-white;
    border-top: 1px solid #f6f6f6;

    ::v-deep {
        .fold-unfold {
            margin-right: $base-padding;
        }
    }

    .tabs-content {
        width: calc(100% - 90px);
        height: $base-tag-item-height;

        ::v-deep {
            .el-tabs__nav-next,
            .el-tabs__nav-prev {
                height: $base-tag-item-height;
                line-height: $base-tag-item-height;
            }

            .el-tabs__header {
                border-bottom: 0;

                .el-tabs__nav {
                    border: 0;
                }

                .el-tabs__item {
                    box-sizing: border-box;
                    height: $base-tag-item-height;
                    // margin-top: 6px;
                    margin-right: 5px;
                    line-height: $base-tag-item-height;
                    /* border: 1px solid $base-border-color; */
                    border: none;
                    border-radius: $base-border-radius $base-border-radius 0 0;
                    transition: padding 0.3s
                        cubic-bezier(0.645, 0.045, 0.355, 1) !important;

                    &.is-active {
                        background-color: var(--column-menu-background);
                        color: var(--color-primary);
                        border: 1px solid var(--column-menu-background) !important;
                        border-bottom: 2px solid var(--color-primary) !important;
                    }
                }
            }
        }
    }

    .more {
        display: flex;
        align-content: center;
        align-items: center;
        cursor: pointer;
    }

    .contextmenu {
        margin: 0;
        background: #fff;
        z-index: 3000;
        position: absolute;
        list-style-type: none;
        padding: 5px 0;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

        li {
            margin: 0;
            padding: 7px 16px;
            cursor: pointer;

            &:hover {
                background: #eee;
            }
        }
    }
}
