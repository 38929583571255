






































.line{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lines{
  display: -webkit-box;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: var(--row);
  -webkit-box-orient: vertical;
}
