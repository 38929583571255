@import "@/styles/variables.scss"; 

















































































.step-two {
    position: relative;
}
.table-container {
    margin-top: 20px;
    overflow: scroll;
}
