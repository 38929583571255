@import "@/styles/variables.scss"; 

























































































































































































































































































































































.avatar {
    width: 35px;
    height: 35px;
    margin-left: 0px;
    margin-top: 4px;
  }
.userDialog{
  .el-dialog__body {
    padding: 10px 0px 0px 10px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
}
