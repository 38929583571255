@import "@/styles/variables.scss"; 




















































































































































































































































.nav-bar-container {
  position: relative;
  height: $base-nav-bar-height;
  padding-right: $base-padding;
  padding-left: $base-padding;
  overflow: hidden;
  user-select: none;
  background: $base-color-white;
  box-shadow: $base-box-shadow;

  .left-panel {
    display: flex;
    align-items: center;
    justify-items: center;
    height: $base-nav-bar-height;

    .fold-unfold {
      color: $base-color-gray;
      cursor: pointer;
      margin-right: 20px;
    }

    ::v-deep {
      .breadcrumb-container {
        /* margin-left: 10px; */
      }
    }
  }

  .right-panel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $base-top-bar-height;

    ::v-deep {
      .user-name {
        color: $base-font-color;
      }

      .user-name + i {
        color: $base-font-color;
      }
      .el-badge {
        margin-right: 15px;
      }

      .icon-class {
        color: $base-font-color !important;
      }
    }
  }
}
.message-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
