@import "@/styles/variables.scss"; 





































.back-to-main-app {
   margin-right: 35px;
}
.el-icon-s-home {
    font-size: 18px;
    cursor: pointer;
}
