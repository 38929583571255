@import "@/styles/variables.scss"; 
























































































































































































































































































































































































































































































































.color-svg-icon {
    -webkit-font-smoothing: antialiased;
    color: #7c7d82;
}

.side-scroll-bar {
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}

div.panel-container {
    padding-bottom: 10px;
}

.no-bottom-margin ::v-deep .el-tabs__header {
    margin-bottom: 0;
}

.indent-left-margin {
    ::v-deep .el-tabs__nav {
        margin-left: 20px;
    }
}

.el-collapse-item ::v-deep ul > li {
    list-style: none;
}

.widget-collapse {
    border-top-width: 0;

    ::v-deep .el-collapse-item__header {
        margin-left: 8px;
        font-style: italic;
        font-weight: bold;
    }

    ::v-deep .el-collapse-item__content {
        padding-bottom: 6px;

        ul {
            padding-left: 10px; /* 重置IE11默认样式 */
            margin: 0; /* 重置IE11默认样式 */
            margin-block-start: 0;
            margin-block-end: 0.25em;
            padding-inline-start: 10px;

            &:after {
                content: "";
                display: block;
                clear: both;
            }

            .container-widget-item,
            .field-widget-item {
                display: inline-block;
                height: 32px;
                line-height: 32px;
                width: 98px;
                float: left;
                margin: 2px 6px 6px 0;
                cursor: move;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                background: #fff;
                border: 1px solid #dcdfe6;
                border-radius: 4px;
                padding: 0 8px;
            }

            .container-widget-item:hover,
            .field-widget-item:hover {
                background: #f1f2f3;
                border-color: $--color-primary;

                .color-svg-icon {
                    color: $--color-primary;
                }
            }

            .drag-handler {
                position: absolute;
                top: 0;
                left: 160px;
                background-color: #dddddd;
                border-radius: 5px;
                padding-right: 5px;
                font-size: 11px;
                color: #666666;
            }
        }
    }
}

.el-card.ft-card {
    border: 1px solid #8896b3;
}

.ft-card {
    margin-bottom: 10px;

    .bottom {
        margin-top: 10px;
        line-height: 12px;
    }

    /*
    .image-zoom {
      height: 500px;
      width: 620px
    }
    */

    .ft-title {
        font-size: 13px;
        font-weight: bold;
    }

    .right-button {
        padding: 0;
        float: right;
    }

    .clear-fix:before,
    .clear-fix:after {
        display: table;
        content: "";
    }

    .clear-fix:after {
        clear: both;
    }
}
