@import "@/styles/variables.scss"; 



































































































.picker-content {
    width: calc(258px - 20px);
}
.upload-demo {
    // width: calc(258px - 20px);
    width: 100px;
    height: 100px;
    ::v-deep {
        .el-upload {
            width: 100%;
        }
    }
}

.item {
    display: block;
    float: left;
    width: 80px;
    height: 80px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
    img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 5px;
    }
    &.upload {
        background: #f7f7f7 none;
        text-align: center;
        /* &::before {
            content: "\e669";
            font-family: "iconfont","element-icons" !important;
            font-size: 16px;
            font-weight: 580;
            line-height: 80px;
            background: #f7f7f7 none;
            background-size: cover;
        } */
        i {
            font-size: 18px;
            line-height: 80px;
        }
    }
    &.is-active {
        box-shadow: 0 0 5px 2px #1890ff;
    }
    &.is-disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}
