@import "@/styles/variables.scss"; 
































































































































































































































































































































































































































































































































































































































































































































































































































































































  li.col-item {
    list-style: none;

    span.col-span-title {
      display: inline-block;
      font-size: 13px;
      width: 120px;
    }

    .col-delete-button {
      margin-left: 6px;
    }
  }

  .panes-setting {
    ul {
      padding-inline-start: 0;
      padding-left: 0; /* 重置IE11默认样式 */
      margin: 0;
    }

    .drag-option {
      cursor: move;
    }

    li.ghost {
      background: #fff;
      border: 2px dotted $--color-primary;
    }
  }

	.small-padding-dialog {
	  ::v-deep .el-dialog__body {
	    padding: 6px 15px 12px 15px;
	  }
	}

	.button-row {
		border-bottom: 1px solid #e1e2e3;
		margin-bottom: 12px;
	}

	.drag-sort-col {
		padding-top: 8px;
		cursor: move;
	}

	.radio-group-custom {
		::v-deep .el-radio-button__inner {
			padding-left: 12px;
			padding-right: 12px;
		}
	}

