@import "@/styles/variables.scss"; 


































































































































































































































































































































































































































































































































































































































































































































































































































































.import-upload {
    width: 100%;

    .el-upload {
        width: 100%;

        .el-upload-dragger {
            width: 100%;
          
        }
    }
}
