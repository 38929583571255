@import "@/styles/variables.scss"; 















































































































































.drawer-container {
  width: 100%;
  min-height: 120px;
  border: 2px dashed #cccccc;
  box-sizing: border-box;
  position: relative;

  .vf-drawer-drop-zone {
    min-height: 58px;
  }
}

.drawer-container.selected {
  outline: 2px solid $--color-primary !important;
}

