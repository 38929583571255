@import "@/styles/variables.scss"; 



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.down-tree {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 15px;

  &-list {
    margin-top: 15px;
    height: 380px;
    overflow-y: auto;
  }
}
