@import "@/styles/variables.scss"; 






















































































































































































































































































.task {
  height: 60px;
}
.close {
  position:absolute;
  top:-5px;
  right:-5px;
  color:#fff;
  cursor:pointer;
  border-radius:50%;
  background:#000;
}
.step-avatar {
  display:flex;
  flex-direction:row;
  position:relative;
  align-items:center
}
.step-avatar .a-img {
  display:flex;
  border-radius:4px;
  flex-direction:column;
  justify-content:center;
  background:#fff;
  position:relative;
}
.step-avatar .el-avatar {
  background:#fff
}
.step-avatar .name {
  text-align:center;
  color:#19191a;
  font-size:14px;
  margin-left:10px
}
::deep .el-timeline {
  padding-left:30px;
}
.show-y {
  justify-content: center;
  flex-direction: column!important;
}
.show-y .name {
  margin-left: 0!important;
}
.line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 5px;
}

.add-user i {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px dashed #909399;
  cursor: pointer
}

.add-user i:hover {
  color: #1682e6;
  border: 1px dashed #1682e6
}

.step-node > div {
  position: absolute;
  display: inline-block
}

.step-node > div:last-child {
  right: 0;
  top: -10px
}

