.drawer >>> .el-drawer__header {
  margin-bottom: 0;
  border-bottom: 1px solid #c5c5c5;
  padding-bottom: 8px;
}
.drawer >>> .el-drawer__body {
  padding-bottom: 44px;
  overflow: hidden;
}
.drawer .pane-tab {
  height: 100%;
}
.drawer .pane-tab >>> .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px;
}
.drawer >>> .el-tabs__item:focus {
  box-shadow: none !important;
}
.drawer >>> .el-tabs__header {
  margin-bottom: 0;
}
.header {
  line-height: 28px;
}
.actions {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px 12px;
  width: 100%;
  box-sizing: border-box;
  text-align: right;
}
.radio-item {
  width: 110px;
  padding: 6px;
}
.priority-select {
  width: 118px;
  position: absolute;
  right: 26px;
}
.form-auth-table {
  font-size: 14px;
}
.form-auth-table .auth-table-header {
  background: #fafafa;
  line-height: 40px;
}
.form-auth-table .row {
  display: flex;
  align-items: center;
  line-height: 32px;
  padding: 8px 12px;
  border-bottom: 1px solid #efefef;
}
.form-auth-table .row:hover {
  background: #f5f7fa;
}
.form-auth-table .row .label {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-auth-table .row .label .required {
  color: #f2725e;
}
.form-auth-table .row .radio-group {
  flex: 2;
  display: flex;
  justify-content: space-between;
}
.approver-pane {
  overflow-y: scroll;
  overflow-x: hidden;
}
.approver-pane .option-box {
  font-size: 14px;
  padding-left: 1rem;
}
.condition-pane {
  height: 100%;
  overflow: scroll;
}
/*# sourceMappingURL=src/components/form/Process/PropPanel/index.css.map */