@import "@/styles/variables.scss"; 


































































.dialog-content {
    position: relative;
}
