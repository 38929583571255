@import "@/styles/variables.scss"; 





















































































.el-table {
  // 解决table组件内容滚动时页面滚动条同步滚动
  overflow: auto;
  // 必须设置
  position: relative;

  ::v-deep .el-table__fixed-header-wrapper thead th > .cell {
    white-space: nowrap !important;  /* 禁止表头换行 */
  }

  ::v-deep .el-table__header-wrapper thead th > .cell {
    white-space: nowrap !important;  /* 禁止表头换行 */
  }

  ::v-deep .el-table__body-wrapper {
    //height: 100% !important;
  }
}

