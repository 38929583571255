@import "@/styles/variables.scss"; 















































































.flowPreviewDialog {
  .el-dialog__body {
    padding: 0;
  }
}
.preview-tab {
  .el-tabs__header {
    &.is-left {
      margin-right: -1px;
    }
  }
  .el-tabs__item{
    padding: 0 30px;
  }
  .el-tabs__content {
    padding-left: 0;
    border-left: 2px solid #dfe4ed;
  }
}
.flow-title {
  padding-left: 10px;
  font-weight: bold;
  color: #82848a;
}
.flow-container {
  display: inline-block;
  background: #f5f5f7;
  padding: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  overflow: auto;
}

.scale-slider {
  position: fixed;
  right: 0;
  z-index: 99;
  padding-right: 10px;

  .btn {
    display: inline-block;
    padding: 4px;
    border: 1px solid #cacaca;
    border-radius: 3px;
    background: #FFF;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
}
