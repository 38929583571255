@import "@/styles/variables.scss"; 






















































































































































































































































































.table-cell {
  //padding: 3px;
  border: 1px dashed #336699;
  display: table-cell;
  position: relative;

  .draggable-div {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
  }

  .form-widget-list {
    border: 1px dashed #336699;
    margin: 3px;

    //min-height: 36px;
    height: 100%;
    display: table-cell;
  }

  .table-cell-action{
    position: absolute;
    //bottom: -30px;
    bottom: 0;
    right: -2px;
    height: 28px;
    line-height: 28px;
    background: $--color-primary;
    z-index: 999;

    i {
      font-size: 14px;
      color: #fff;
      margin: 0 5px;
      cursor: pointer;
    }
  }

  .table-cell-handler {
    position: absolute;
    top: -2px;
    //bottom: -24px;  /* 拖拽手柄位于组件下方，有时无法正常拖动，原因未明？？ */
    left: -2px;
    height: 22px;
    line-height: 22px;
    background: $--color-primary;
    z-index: 9;

    i {
      font-size: 14px;
      font-style: normal;
      color: #fff;
      margin: 4px;
      cursor: default;  //cursor: move;
    }
  }

}

.table-cell.selected {
  outline: 2px solid $--color-primary !important;
}
