









































































































































































































































.app-flows {
  padding: 20px 20px 0;
  position: relative;
  /deep/ .el-collapse {
    padding: 0 15px;
    background: #ffffff;
    .el-collapse-item__header {
      font-size: medium;
    }
    .el-collapse-item__wrap {
      padding: 20px 10px;
    }
  }

  .form-item {
    padding: 15px 10px;
    width: 200px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #d9dada;
    border-radius: 5px;
    float: left;
    margin: 5px 10px;
    height:67px;
    &:hover {
       border: 1px solid #1890ff;
       span {
         display: inline-block !important;
       }
    }
    i {
      padding: 8px;
      border-radius: 8px;
      float: left;
      font-size: 20px;
      color: #ffffff;
      background: #38adff;
    }
    div {
      display: inline-block;
      height: 36px;
      line-height: 36px;
      div{
        display: inline-block;
        margin-left: 10px;
        width: 100px;
      }
      span {
        display: none;
        float: right;
        color: #38adff;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .form-item {
    padding: 12px 10px !important;
    width: 150px !important;
    margin: 5px !important;
    &:hover {
      span:last-child {
        display: none !important;
      }
    }
  }
}
