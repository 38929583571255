@import "@/styles/variables.scss"; 





























































































































































































::v-deep.el-dialog {
    margin: 5vh auto !important;
}

::v-deep .el-dialog__body {
    height: 70vh;
    overflow: auto;
}
