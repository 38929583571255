.dv-water-pond-level {
  position: relative;
}
.dv-water-pond-level svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.dv-water-pond-level text {
  font-size: 25px;
  font-weight: bold;
  text-anchor: middle;
  dominant-baseline: middle;
}
.dv-water-pond-level ellipse,
.dv-water-pond-level rect {
  fill: none;
  stroke-width: 3;
}
.dv-water-pond-level canvas {
  margin-top: 8px;
  margin-left: 8px;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  box-sizing: border-box;
}
