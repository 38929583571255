@import "@/styles/variables.scss"; 





























































































































































































































































































































































































































































































































































































































































































































































.collapse-container {
  margin: 2px;

  .form-widget-list {
    min-height: 28px;
  }
}

::v-deep .el-collapsed__header {
  padding: 10px 12px;
}
