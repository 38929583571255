@import "@/styles/variables.scss"; 





























































































































































































































































































































































































































































.drawing-table table {
    border-collapse: collapse;
    width: 100%;
}

.drawing-table tr,
.drawing-table td {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 80px;
    min-height: 40px;
}

.context-menu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}

.context-menu li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
}

.context-menu li:hover {
    background: #eee;
}
