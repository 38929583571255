@import "@/styles/variables.scss"; 



















































.animate-popover {
  ::v-deep {
    .el-dialog {
      position: absolute;
      // 根据项目中弹窗的大小设定的居中，用其他方式实现居中也行
      left: calc(50% - 290px);
      top: calc(50% - 250px);
      overflow: hidden;
      width: 580px;
      height: 500px;
      &__title {
        color: $base-color-default;
        font-weight: bolder;
      }
      &__wrapper {
        &.dialog-fade-enter-active {
          -webkit-animation: flipInY 1s !important;
          animation: flipInY 1s !important;
        }
      }
    }
  }
}

.code-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.code-img {
  border: 1px solid $base-border-color;
  width: 258px;
  height: 258px;
}
.text {
  color: #000;
  font-weight: bolder;
  margin-top: 30px;
  font-size: 16px;
}
