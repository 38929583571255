@import "@/styles/variables.scss"; 































































































































































































































































.p-20 {
    padding: 20px;
}
