@import "@/styles/variables.scss"; 






































































































































.ace-editor {
  min-height: 300px;
}
