@import "@/styles/variables.scss"; 






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































	.down-tree {
    margin-top: 10px;
		border: 1px solid rgba(0, 0, 0, 0.05);
		padding: 15px;

		&-list {
			margin-top: 15px;
			height: 380px;
			overflow-y: auto;
		}
	}
  .tree-node {
    font-size: 14px;
  }
  .tree-node-leaf {
    /*-webkit-user-drag: element;*/
    user-select: none;
  }
  .tree-node-root {
    user-select: none;
  }
  .custom-tree-node {
    width: 100%;
    display: flex;
    align-items: center;
    height: 26px;
    padding-right: 5px;
  }
  .custom-tree-node-img {
    width:20px; 
    height:20px; 
    margin-right:6px
  }
  .custom-tree-node-label {
    flex: 1;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-tree-node-button {
    display: none;
  }
  .custom-tree-node:hover .custom-tree-node-button {
    display: inline-block;
  }
