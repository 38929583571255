@import "@/styles/variables.scss"; 












































































































































































.comprehensive-tabs {
    margin-left: 20px;
    width: 600px;
    ::v-deep {
        .el-tabs__header {
            margin: 0 0 5px;
        }

        .el-tabs__nav-wrap::after {
            background-color: #fff !important;
        }

        .el-tabs__item i {
            margin-right: 3px;
        }
        // .el-tabs__nav-scroll {
        //     width: 500px;
        // }
    }
}
@media screen and (max-width: 1200px) {
    .comprehensive-tabs {
        width: 800px;
    }
}

@media screen and (max-width: 992px) {
    .comprehensive-tabs {
        width: 700px;
    }
}

@media screen and (max-width: 768px) {
    .comprehensive-tabs {
        width: 600px;
    }
}

@media screen and (max-width: 576px) {
}
