


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'styles/index.styl';

.calc-dialog .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
}

.dst-item .el-tag {
    margin-left: 10px;
    float: right;
    margin-top: -5px;
    margin-right: 8px;
    cursor: pointer;
}

/deep/.el-divider--horizontal {
    margin: 30px 0 20px 0;
}

.dstrow {
    padding: 6px;
}
