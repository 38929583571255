.setting-container {
  width: 600px;
  height: 100%;
  margin: auto;
  background: #fff;
  padding: 16px;
}
.setting-container >>>.el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
/*# sourceMappingURL=src/components/form/AdvancedSetting/index.css.map */