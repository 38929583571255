@font-face {
  font-family: "iconfont"; /* Project id 4106173 */
  src: url('iconfont.woff2?t=1687247392056') format('woff2'),
       url('iconfont.woff?t=1687247392056') format('woff'),
       url('iconfont.ttf?t=1687247392056') format('truetype'),
       url('iconfont.svg?t=1687247392056#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  /* font-size: 16px; */
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-diannao1:before {
  content: "\e78f";
}

.icon-shouji2:before {
  content: "\e790";
}

.icon-yunshangchuan:before {
  content: "\eb2c";
}

.icon-yunxiazai:before {
  content: "\eb2d";
}

.icon-daoru_o:before {
  content: "\eb49";
}

.icon-daochu_o:before {
  content: "\eb4b";
}

.icon-pin-copy:before {
  content: "\e9f5";
}

.icon-pin-full:before {
  content: "\e9f3";
}

.icon-paixu:before {
  content: "\e78d";
}

.icon-zhankai3:before {
  content: "\e78e";
}

.icon-yuedu:before {
  content: "\e76d";
}

.icon-yuyuedan:before {
  content: "\e76f";
}

.icon-yuyuedan1:before {
  content: "\e771";
}

.icon-yuyuedingdan:before {
  content: "\e772";
}

.icon-yuyuedingdan1:before {
  content: "\e773";
}

.icon-zaixianceping:before {
  content: "\e774";
}

.icon-zan:before {
  content: "\e775";
}

.icon-zantinganniu:before {
  content: "\e776";
}

.icon-zhankai:before {
  content: "\e777";
}

.icon-zan1:before {
  content: "\e778";
}

.icon-zhankai1:before {
  content: "\e779";
}

.icon-zhaopingangwei:before {
  content: "\e77a";
}

.icon-zhaoshengzige:before {
  content: "\e77b";
}

.icon-zhibo:before {
  content: "\e77c";
}

.icon-zhibobofangshexiangjitianxianmianxing:before {
  content: "\e77d";
}

.icon-zhibozhong-0:before {
  content: "\e77e";
}

.icon-zhaoshengjihua:before {
  content: "\e77f";
}

.icon-zhifubao:before {
  content: "\e780";
}

.icon-zhiyeweisheng:before {
  content: "\e781";
}

.icon-zhiyuan:before {
  content: "\e782";
}

.icon-zhiyuan-01:before {
  content: "\e783";
}

.icon-zhiyuanmeidianji:before {
  content: "\e784";
}

.icon-zhuanye:before {
  content: "\e785";
}

.icon-zixun:before {
  content: "\e786";
}

.icon-zhuomian:before {
  content: "\e787";
}

.icon-ziyuan:before {
  content: "\e788";
}

.icon-ziyuan131:before {
  content: "\e789";
}

.icon-zongheyunyingfenxi:before {
  content: "\e78a";
}

.icon-zuobiao:before {
  content: "\e78b";
}

.icon-ziyuan146:before {
  content: "\e78c";
}

.icon-apps:before {
  content: "\e770";
}

.icon-tuding:before {
  content: "\e76e";
}

.icon-square:before {
  content: "\e76b";
}

.icon-checkbox-checked-fill:before {
  content: "\e76c";
}

.icon-iconfontzhizuobiaozhun023125:before {
  content: "\e76a";
}

.icon-shijuan:before {
  content: "\e6e2";
}

.icon-shitiziyuanku:before {
  content: "\e6e3";
}

.icon-shizhong:before {
  content: "\e6e4";
}

.icon-shijian1:before {
  content: "\e6e5";
}

.icon-shizhong1:before {
  content: "\e6e6";
}

.icon-shixinchaxun:before {
  content: "\e6e7";
}

.icon-shizhong2:before {
  content: "\e6e8";
}

.icon-shouji:before {
  content: "\e6e9";
}

.icon-shouji1:before {
  content: "\e6ea";
}

.icon-shoucang:before {
  content: "\e6eb";
}

.icon-shouqi:before {
  content: "\e6ec";
}

.icon-shouqi1:before {
  content: "\e6ed";
}

.icon-shouye:before {
  content: "\e6ee";
}

.icon-shouye1:before {
  content: "\e6ef";
}

.icon-shouye-:before {
  content: "\e6f0";
}

.icon-shouyedianji:before {
  content: "\e6f1";
}

.icon-shouyemeidianji:before {
  content: "\e6f2";
}

.icon-shu:before {
  content: "\e6f3";
}

.icon-shuji:before {
  content: "\e6f4";
}

.icon-shijian:before {
  content: "\e6f5";
}

.icon-shuaxin:before {
  content: "\e6f6";
}

.icon-shuju:before {
  content: "\e6f7";
}

.icon-shuju2:before {
  content: "\e6f8";
}

.icon-shuju1:before {
  content: "\e6f9";
}

.icon-shuju4:before {
  content: "\e6fa";
}

.icon-shuju3:before {
  content: "\e6fb";
}

.icon-shuoming:before {
  content: "\e6fc";
}

.icon-shujuyewu:before {
  content: "\e6fd";
}

.icon-sousuo:before {
  content: "\e6fe";
}

.icon-shuoming1:before {
  content: "\e6ff";
}

.icon-sousuoliebiao:before {
  content: "\e700";
}

.icon-suoding:before {
  content: "\e701";
}

.icon-banquan-kuai1:before {
  content: "\e602";
}

.icon-tianchongxing-:before {
  content: "\e702";
}

.icon-banquan-kuai:before {
  content: "\e603";
}

.icon-tianjia:before {
  content: "\e703";
}

.icon-aaaziyuan:before {
  content: "\e604";
}

.icon-tianjia1:before {
  content: "\e704";
}

.icon-aixin_shixin:before {
  content: "\e605";
}

.icon--tianjiatupian:before {
  content: "\e705";
}

.icon-a-gongzuotaigongzuozhuo-03:before {
  content: "\e606";
}

.icon-tianxie:before {
  content: "\e706";
}

.icon-aixin_shixin1:before {
  content: "\e607";
}

.icon-tianxie3:before {
  content: "\e707";
}

.icon-baomingbeifen:before {
  content: "\e608";
}

.icon-tianxie2:before {
  content: "\e708";
}

.icon-B4:before {
  content: "\e609";
}

.icon-tianxie4:before {
  content: "\e709";
}

.icon-B1:before {
  content: "\e60a";
}

.icon-tianxie1:before {
  content: "\e70a";
}

.icon-B:before {
  content: "\e60b";
}

.icon-tianxie5:before {
  content: "\e70b";
}

.icon-biaoge:before {
  content: "\e60c";
}

.icon-tihuan:before {
  content: "\e70c";
}

.icon-B3:before {
  content: "\e60d";
}

.icon-time:before {
  content: "\e70d";
}

.icon-baogao:before {
  content: "\e60e";
}

.icon-toBottom-fill:before {
  content: "\e70e";
}

.icon-baifenwei:before {
  content: "\e60f";
}

.icon-tiepingshen:before {
  content: "\e70f";
}

.icon-bofang:before {
  content: "\e610";
}

.icon-tixian:before {
  content: "\e710";
}

.icon-bofang1:before {
  content: "\e611";
}

.icon-tongzhi:before {
  content: "\e711";
}

.icon-a-gongzuotaigongzuozhuo-031:before {
  content: "\e612";
}

.icon-tongzhi1:before {
  content: "\e712";
}

.icon-auth:before {
  content: "\e613";
}

.icon-toTop:before {
  content: "\e713";
}

.icon-dagou1:before {
  content: "\e614";
}

.icon-tuandui:before {
  content: "\e714";
}

.icon-canguanyuyue:before {
  content: "\e615";
}

.icon-tubiao:before {
  content: "\e715";
}

.icon-boshimao:before {
  content: "\e616";
}

.icon-tuiguangqingkuang:before {
  content: "\e716";
}

.icon-bi:before {
  content: "\e617";
}

.icon-tuiguang:before {
  content: "\e717";
}

.icon-bili:before {
  content: "\e618";
}

.icon-tupian:before {
  content: "\e718";
}

.icon-chakan2:before {
  content: "\e619";
}

.icon-tuiguangshouyi:before {
  content: "\e719";
}

.icon-chakan:before {
  content: "\e61a";
}

.icon-untitled:before {
  content: "\e71a";
}

.icon-chazhao:before {
  content: "\e61b";
}

.icon-tutubiao:before {
  content: "\e71b";
}

.icon-canguanyuyue1:before {
  content: "\e61c";
}

.icon-untitled1:before {
  content: "\e71c";
}

.icon-B2:before {
  content: "\e61d";
}

.icon-untitled2:before {
  content: "\e71d";
}

.icon-chazhao1:before {
  content: "\e61e";
}

.icon-untitled3:before {
  content: "\e71e";
}

.icon-ceping:before {
  content: "\e61f";
}

.icon-untitled5:before {
  content: "\e71f";
}

.icon-chaxun1:before {
  content: "\e620";
}

.icon-untitled4:before {
  content: "\e720";
}

.icon-boshiweb_biaoqing:before {
  content: "\e621";
}

.icon-untitled6:before {
  content: "\e721";
}

.icon-biaoqian:before {
  content: "\e622";
}

.icon-untitled7:before {
  content: "\e722";
}

.icon-comment:before {
  content: "\e623";
}

.icon-verification-code:before {
  content: "\e723";
}

.icon-beizhuyitianxie:before {
  content: "\e624";
}

.icon-verification-code1:before {
  content: "\e724";
}

.icon-collection-b:before {
  content: "\e625";
}

.icon-untitled8:before {
  content: "\e725";
}

.icon-c-tequan:before {
  content: "\e626";
}

.icon-vipdiamond-f:before {
  content: "\e726";
}

.icon-chaxun2:before {
  content: "\e627";
}

.icon-wangxiala:before {
  content: "\e727";
}

.icon-chuanzhen:before {
  content: "\e628";
}

.icon-weibiaoti--:before {
  content: "\e728";
}

.icon-daefenqi:before {
  content: "\e629";
}

.icon-weixin1:before {
  content: "\e729";
}

.icon-chaxun:before {
  content: "\e62a";
}

.icon-weixin:before {
  content: "\e72a";
}

.icon-dagou:before {
  content: "\e62b";
}

.icon-weixinzhifu:before {
  content: "\e72b";
}

.icon-dagouyouquan:before {
  content: "\e62c";
}

.icon-weizhi:before {
  content: "\e72c";
}

.icon-app:before {
  content: "\e62d";
}

.icon-wj-zyzdj:before {
  content: "\e72d";
}

.icon-beizhu:before {
  content: "\e62e";
}

.icon-wodedianji:before {
  content: "\e72e";
}

.icon-cai:before {
  content: "\e62f";
}

.icon-wode:before {
  content: "\e72f";
}

.icon-daka:before {
  content: "\e630";
}

.icon-wodemeidianji:before {
  content: "\e730";
}

.icon-daojishi:before {
  content: "\e631";
}

.icon-xiajiantou:before {
  content: "\e731";
}

.icon-chaweici:before {
  content: "\e632";
}

.icon-woyaofenxiao:before {
  content: "\e732";
}

.icon-chenggong:before {
  content: "\e633";
}

.icon-xiala:before {
  content: "\e733";
}

.icon-chakan1:before {
  content: "\e634";
}

.icon-wodeguanzhu:before {
  content: "\e734";
}

.icon-dailishang:before {
  content: "\e635";
}

.icon-xiala1:before {
  content: "\e735";
}

.icon-beijingdaxue:before {
  content: "\e636";
}

.icon-xiala2:before {
  content: "\e736";
}

.icon-daka1:before {
  content: "\e637";
}

.icon-xianxingzhifeijitubiao:before {
  content: "\e737";
}

.icon-daiban:before {
  content: "\e638";
}

.icon-xiaochengxu:before {
  content: "\e738";
}

.icon-daxue-:before {
  content: "\e639";
}

.icon-xianxingrenwutubiao:before {
  content: "\e739";
}

.icon-delete:before {
  content: "\e63a";
}

.icon-xiaochengxu1:before {
  content: "\e73a";
}

.icon-daka3x:before {
  content: "\e63b";
}

.icon-xiaoxi:before {
  content: "\e73b";
}

.icon-diannao:before {
  content: "\e63c";
}

.icon-xiaolian:before {
  content: "\e73c";
}

.icon-daikuanceping:before {
  content: "\e63d";
}

.icon-xiaoxi1:before {
  content: "\e73d";
}

.icon-datiqia:before {
  content: "\e63e";
}

.icon-xiazai:before {
  content: "\e73e";
}

.icon-dianzan:before {
  content: "\e63f";
}

.icon-xihuan:before {
  content: "\e73f";
}

.icon-dianhua2:before {
  content: "\e640";
}

.icon-xiazai1:before {
  content: "\e740";
}

.icon-didian:before {
  content: "\e641";
}

.icon-xihuan1:before {
  content: "\e741";
}

.icon-dingdanchaxun:before {
  content: "\e642";
}

.icon-xing:before {
  content: "\e742";
}

.icon-biaoqing:before {
  content: "\e643";
}

.icon-xin:before {
  content: "\e743";
}

.icon-dianzan1:before {
  content: "\e644";
}

.icon-xingbienan:before {
  content: "\e744";
}

.icon-diyi:before {
  content: "\e645";
}

.icon-xinghao:before {
  content: "\e745";
}

.icon-dizhi:before {
  content: "\e646";
}

.icon-xingbienv:before {
  content: "\e746";
}

.icon-dingdanwenjian:before {
  content: "\e647";
}

.icon-xingping:before {
  content: "\e747";
}

.icon-dongtaixuanzhong:before {
  content: "\e648";
}

.icon-xinrenkecheng:before {
  content: "\e748";
}

.icon-duihua:before {
  content: "\e649";
}

.icon-xinxin_20201123150227:before {
  content: "\e749";
}

.icon-fanhui:before {
  content: "\e64a";
}

.icon-xingxing:before {
  content: "\e74a";
}

.icon-dizhi1:before {
  content: "\e64b";
}

.icon-xuankechaxun:before {
  content: "\e74b";
}

.icon-dui:before {
  content: "\e64c";
}

.icon-xuankeceping:before {
  content: "\e74c";
}

.icon-fenshuxian:before {
  content: "\e64d";
}

.icon-xinyongqiapindao:before {
  content: "\e74d";
}

.icon-duihua1:before {
  content: "\e64e";
}

.icon-xuanze:before {
  content: "\e74e";
}

.icon-fangda:before {
  content: "\e64f";
}

.icon-xuesheng:before {
  content: "\e74f";
}

.icon-edit-1-copy:before {
  content: "\e650";
}

.icon-xueshengguanli:before {
  content: "\e750";
}

.icon-fenxiang1:before {
  content: "\e651";
}

.icon-xueshengguanli1:before {
  content: "\e751";
}

.icon-fenshuxian1:before {
  content: "\e652";
}

.icon-xueshimao:before {
  content: "\e752";
}

.icon-fenxiang2:before {
  content: "\e653";
}

.icon-xuexiao:before {
  content: "\e753";
}

.icon-fenzhongxiangying:before {
  content: "\e654";
}

.icon-xuexiaobeifen:before {
  content: "\e754";
}

.icon-fuwu:before {
  content: "\e655";
}

.icon-xuexiaogaikuang:before {
  content: "\e755";
}

.icon-fenxiang:before {
  content: "\e656";
}

.icon-xuexiao1:before {
  content: "\e756";
}

.icon-fuzhilianjie:before {
  content: "\e657";
}

.icon-yanjing:before {
  content: "\e757";
}

.icon-gantanhao:before {
  content: "\e658";
}

.icon-yajun:before {
  content: "\e758";
}

.icon-gaishuai:before {
  content: "\e659";
}

.icon-yanjing_xianshi_o:before {
  content: "\e759";
}

.icon-gaokaozhenti:before {
  content: "\e65a";
}

.icon-yanjing_xianshi_o1:before {
  content: "\e75a";
}

.icon-gaokao:before {
  content: "\e65b";
}

.icon-yanjing_yincang_o:before {
  content: "\e75b";
}

.icon-gengduo2:before {
  content: "\e65c";
}

.icon-yanzhengma:before {
  content: "\e75c";
}

.icon-gengduo-2:before {
  content: "\e65d";
}

.icon-yanjing_yincang_o1:before {
  content: "\e75d";
}

.icon-gengduo4:before {
  content: "\e65e";
}

.icon-yanzhengma1:before {
  content: "\e75e";
}

.icon-gengduo5:before {
  content: "\e65f";
}

.icon-yaoqing:before {
  content: "\e75f";
}

.icon-gengduo1:before {
  content: "\e660";
}

.icon-yishu:before {
  content: "\e760";
}

.icon-gengduo3:before {
  content: "\e661";
}

.icon-yiwen:before {
  content: "\e761";
}

.icon-gerenjianjie:before {
  content: "\e662";
}

.icon-yizan:before {
  content: "\e762";
}

.icon-gerenzhongxin1:before {
  content: "\e663";
}

.icon-yonghu:before {
  content: "\e763";
}

.icon-gongzhang:before {
  content: "\e664";
}

.icon-yonghu1:before {
  content: "\e764";
}

.icon-gongsi1:before {
  content: "\e665";
}

.icon-yonghushiyongtongji:before {
  content: "\e765";
}

.icon-gouwuche1:before {
  content: "\e666";
}

.icon-you-6:before {
  content: "\e766";
}

.icon-gongzuotai:before {
  content: "\e667";
}

.icon-yuanxiao:before {
  content: "\e767";
}

.icon-gongzuo:before {
  content: "\e668";
}

.icon-yuce:before {
  content: "\e768";
}

.icon-guanyuwomen:before {
  content: "\e669";
}

.icon-yunyingbaogao1:before {
  content: "\e769";
}

.icon-guanjun:before {
  content: "\e66a";
}

.icon-hezuo:before {
  content: "\e66b";
}

.icon-guanzhu:before {
  content: "\e66c";
}

.icon-h5e:before {
  content: "\e66d";
}

.icon-hua:before {
  content: "\e66e";
}

.icon-houtaiguanli:before {
  content: "\e66f";
}

.icon-huiyuan:before {
  content: "\e670";
}

.icon-huati:before {
  content: "\e671";
}

.icon-gengduo:before {
  content: "\e672";
}

.icon-huiyuan1:before {
  content: "\e673";
}

.icon-huo:before {
  content: "\e674";
}

.icon-hezuo1tubiao:before {
  content: "\e675";
}

.icon-huo1:before {
  content: "\e676";
}

.icon-huiyuan2:before {
  content: "\e677";
}

.icon-icon_A:before {
  content: "\e678";
}

.icon-icon_play:before {
  content: "\e679";
}

.icon-huiyuan121:before {
  content: "\e67a";
}

.icon-icon--:before {
  content: "\e67b";
}

.icon-hongbao:before {
  content: "\e67c";
}

.icon-icon_personal:before {
  content: "\e67d";
}

.icon-iconfontxuexiaoguanli:before {
  content: "\e67e";
}

.icon-iconfontdianhua1:before {
  content: "\e67f";
}

.icon-gerenzhongxin:before {
  content: "\e680";
}

.icon-iconguifanbeifen:before {
  content: "\e681";
}

.icon-icon-xian-02:before {
  content: "\e682";
}

.icon-icon-xian-11:before {
  content: "\e683";
}

.icon-icon:before {
  content: "\e684";
}

.icon-iconzhengli_youjian:before {
  content: "\e685";
}

.icon-jiahao:before {
  content: "\e686";
}

.icon-icon-yxj-remark:before {
  content: "\e687";
}

.icon-IMliaotian:before {
  content: "\e688";
}

.icon-jiangpai:before {
  content: "\e689";
}

.icon-icon-test-copy:before {
  content: "\e68a";
}

.icon-jiaoyu:before {
  content: "\e68b";
}

.icon-jiangbei:before {
  content: "\e68c";
}

.icon-jiesuo:before {
  content: "\e68d";
}

.icon-jifenshangcheng:before {
  content: "\e68e";
}

.icon-jigou:before {
  content: "\e68f";
}

.icon--jinbi:before {
  content: "\e690";
}

.icon-jijun:before {
  content: "\e691";
}

.icon-kaishishangke:before {
  content: "\e692";
}

.icon-jishiben:before {
  content: "\e693";
}

.icon-jinbi:before {
  content: "\e694";
}

.icon-kaishishangke1:before {
  content: "\e695";
}

.icon-kecheng2:before {
  content: "\e696";
}

.icon-kaitonghuiyuan:before {
  content: "\e697";
}

.icon-kechengguanli1:before {
  content: "\e698";
}

.icon-kefu1:before {
  content: "\e699";
}

.icon-kefuzhongxin:before {
  content: "\e69a";
}

.icon-kechengguanli:before {
  content: "\e69b";
}

.icon-ketangdianji:before {
  content: "\e69c";
}

.icon-ketangmeidianji:before {
  content: "\e69d";
}

.icon-kefu:before {
  content: "\e69e";
}

.icon-laba:before {
  content: "\e69f";
}

.icon-kemu:before {
  content: "\e6a0";
}

.icon-lajitong1:before {
  content: "\e6a1";
}

.icon-lajitong-copy:before {
  content: "\e6a2";
}

.icon-liebiao:before {
  content: "\e6a3";
}

.icon-laoshi:before {
  content: "\e6a4";
}

.icon-liebiao1:before {
  content: "\e6a5";
}

.icon-lajitong:before {
  content: "\e6a6";
}

.icon-liebiaoxingshi:before {
  content: "\e6a7";
}

.icon-lifangtilitiduomiantifangkuai:before {
  content: "\e6a8";
}

.icon-liebiaoxingshi1:before {
  content: "\e6a9";
}

.icon-lijirenzheng:before {
  content: "\e6aa";
}

.icon-lipin:before {
  content: "\e6ab";
}

.icon-mima1:before {
  content: "\e6ac";
}

.icon-mima:before {
  content: "\e6ad";
}

.icon-mn_paiming_fill:before {
  content: "\e6ae";
}

.icon-luntanzixun:before {
  content: "\e6af";
}

.icon-paihangshuju:before {
  content: "\e6b0";
}

.icon-pengyouquan1:before {
  content: "\e6b1";
}

.icon-pengyouquan:before {
  content: "\e6b2";
}

.icon-pic_none:before {
  content: "\e6b3";
}

.icon-pic_none1:before {
  content: "\e6b4";
}

.icon-pinglun3x:before {
  content: "\e6b5";
}

.icon-pinglun1:before {
  content: "\e6b6";
}

.icon-pinglun:before {
  content: "\e6b7";
}

.icon-qia:before {
  content: "\e6b8";
}

.icon-qianbao1:before {
  content: "\e6b9";
}

.icon-qianbao:before {
  content: "\e6ba";
}

.icon-qiapian:before {
  content: "\e6bb";
}

.icon-qian2:before {
  content: "\e6bc";
}

.icon-qiyerencaiku_hua:before {
  content: "\e6bd";
}

.icon-qq:before {
  content: "\e6be";
}

.icon-qianbao3:before {
  content: "\e6bf";
}

.icon-QQ1:before {
  content: "\e6c0";
}

.icon-quanping:before {
  content: "\e6c1";
}

.icon-panduan:before {
  content: "\e6c2";
}

.icon-quanyi:before {
  content: "\e6c3";
}

.icon-queding:before {
  content: "\e6c4";
}

.icon-qunfengqqkongjian:before {
  content: "\e6c5";
}

.icon-qunfengxinlangweibo:before {
  content: "\e6c6";
}

.icon-quxiao:before {
  content: "\e6c7";
}

.icon-quanyou00:before {
  content: "\e6c8";
}

.icon-quxiao1:before {
  content: "\e6c9";
}

.icon-quxiao2:before {
  content: "\e6ca";
}

.icon-sanjiao:before {
  content: "\e6cb";
}

.icon-renshizhuanyexitong_neiburencaishichang:before {
  content: "\e6cc";
}

.icon-renzheng:before {
  content: "\e6cd";
}

.icon-renzheng1:before {
  content: "\e6ce";
}

.icon-search:before {
  content: "\e6cf";
}

.icon-shaixuan:before {
  content: "\e6d0";
}

.icon-shaixuan1:before {
  content: "\e6d1";
}

.icon-shaixuan3:before {
  content: "\e6d2";
}

.icon-shaixuan2:before {
  content: "\e6d3";
}

.icon-shanchu:before {
  content: "\e6d4";
}

.icon-shanchu1:before {
  content: "\e6d5";
}

.icon-shanchu2:before {
  content: "\e6d6";
}

.icon-shang-:before {
  content: "\e6d7";
}

.icon-shangjiantou:before {
  content: "\e6d8";
}

.icon-shebeizonghekanban:before {
  content: "\e6d9";
}

.icon-shebeizonghekanban1:before {
  content: "\e6da";
}

.icon-sheng:before {
  content: "\e6db";
}

.icon-shequdianji:before {
  content: "\e6dc";
}

.icon-shequmeidianji:before {
  content: "\e6dd";
}

.icon-shexiangtou:before {
  content: "\e6de";
}

.icon-shequ-weijihuo:before {
  content: "\e6df";
}

.icon-shezhi1:before {
  content: "\e6e0";
}

.icon-shezhi:before {
  content: "\e6e1";
}

