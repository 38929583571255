@import "@/styles/variables.scss"; @import 'vxe-table/styles/variable.scss';

// 修改样式变量
$vxe-table-header-background-color: #fff;
//$vxe-table-header-font-color: #0a457c;
//$vxe-font-color: #606266;
//$vxe-primary-color: #409eff; // 分页按钮等
//$vxe-table-font-color: $vxe-font-color;
$vxe-table-border-color: #e8eaec;
$vxe-table-border-radius: 4px;
$vxe-table-row-striped-background-color: #fff;

@import 'vxe-table/styles/index.scss';

.vxe-toolbar {
  //padding-left: 10px;
  padding-right: 10px;
}
.vxe-tools--operate {
		.vxe-button {
			border: none !important;
			&:hover {
				border: none !important;
				box-shadow: none;

			}
			&:focus {
				border: none !important;
				color: #1890ff;
				box-shadow: none;
			}
		}

}
