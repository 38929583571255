@import "@/styles/variables.scss"; 






























































.animate__animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.semiangle-code {
  position: absolute;
  z-index: 1;
  right: 2px;
  bottom: -2px;
  &__iamge {
    width: 85px;
    height: 84px;
  }
}
.code {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  ::v-deep {
    .el-tabs {
      &__nav-scroll {
        width: 50%;
        margin: 0 auto;
      }
      &__nav {
        transform: translateX(50%) !important;
      }
    }
  }
  .code-content {
    margin-top: 30px;
    .code-img {
      width: 164px;
      height: 164px;
    }
    .text {
      color: #000;
      font-size: 16px;
      margin-top: 20px;
      letter-spacing: 1px;
    }
  }
  .cacel-btn {
    display: inherit;
    width: 280px;
    height: 42px;
    border-radius: 25px;
    margin-top: 5px;
    border: 0;
    margin-right: auto;
    margin-left: auto;
    letter-spacing: 0.3em;
    font-size: 15px;
    margin-top: 30px;
    &:hover {
      opacity: 0.9;
    }
  }
}
