.cmp-container {
  line-height: 30px;
  padding: 10px;
}
.cmp-container >>> .el-input--small .el-input__inner {
  padding-left: 10px;
  padding-right: 15px;
}
.cmp-container >>> .el-input-number.is-controls-right .el-input__inner {
  padding-left: 15px;
  padding-right: 0;
  text-align: left;
}
.cmp-container >>> .el-input:hover .el-input__inner {
  border-color: #529eff;
}
.label {
  font-size: 12px;
  padding-right: 16px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.range-title {
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon-wrapper i {
  cursor: pointer;
  color: #c5c5c5;
}
.icon-wrapper i:hover {
  color: #333;
}
/*# sourceMappingURL=src/components/form/Process/PropPanel/NumInput.css.map */