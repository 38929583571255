@import "@/styles/variables.scss"; 
































































































































































































































































































































































































































































































































































































































































































































































































.el-container.main-container {
    &.full-height {
        display: flex;
    }

    .el-container.main-content {
        display: inline-flex;
        height: 100%;
        width: 100%;
        min-width: 1246px;
        flex-direction: row;
        flex-shrink: 1;
        overflow-y: hidden;
        overflow-x: auto;

        .el-aside.side-panel {
            height: 100%;
            .el-scrollbar {
                height: 100%;
            }
        }

        .left-aside-toggle-bar {
            display: block;
            cursor: pointer;
            height: 54px;
            width: 18px;
            position: absolute;
            top: calc(50% - 18px);
            // left: 258px;
            left: -2px;
            border-radius: 0 8px 8px 0;
            background: #fff;
            z-index: 8;
            padding-top: 16px;

            i {
                font-size: 28px;
                color: #909399;
                margin-left: -7px;
            }
        }

        .left-aside-toggle-bar:hover {
            i {
                color: $--color-primary;
            }
        }

        .left-aside-toggle-bar.aside-hidden {
            left: -2px;
        }

        .right-aside-toggle-bar {
            display: block;
            cursor: pointer;
            height: 54px;
            width: 18px;
            position: absolute;
            top: calc(50% - 18px);
            // right: 298px;
            right: -2px;
            border-radius: 8px 0 0 8px;
            background: #fff;
            z-index: 8;
            padding-top: 16px;
            padding-right: -5px !important;

            i {
                font-size: 28px;
                color: #909399;
                position: relative;
                top: 0px;
                left: -4px;
            }
        }

        .right-aside-toggle-bar:hover {
            i {
                color: $--color-primary;
            }
        }

        .right-aside-toggle-bar.aside-hidden {
            right: -2px;
        }

        .el-main.form-widget-main {
            ::v-deep .el-scrollbar {
                height: 100%;
                .el-scrollbar__view {
                    height: 100%;
                }
            }
            ::v-deep .form-widget-container {
                overflow-y: hidden;
                height: 100%;
                box-sizing: border-box;

                .el-form.full-height-width {
                    box-sizing: border-box;
                }
                .form-widget-canvas {
                    line-height: 100%;
                    box-sizing: border-box;
                }
            }
        }

        .el-aside.setting-panel {
            height: 100%;
            overflow-y: hidden;
            display: flex;
            flex-basis: auto;
            // min-width:330px;

            ::v-deep .panel-container {
                padding: 0;

                .el-tabs {
                    display: flex;
                    flex-direction: column;
                }
                .el-tabs__header {
                    padding: 0 8px;
                }
                .el-tabs__content,
                .el-tab-pane {
                    height: 100%;
                    flex-grow: 1;
                }
                .el-scrollbar__wrap {
                    padding-left: 8px;
                    padding-right: 12px;
                }
            }
        }
    }
}

.el-container.main-container {
    background: #fff;

    ::v-deep aside {
        /* 防止aside样式被外部样式覆盖！！ */
        margin: 0;
        padding: 0;
        background: inherit;
    }
}

.el-container.full-height {
    height: 100%;
    overflow-y: hidden;
}

.el-container.center-layout-container {
    min-width: 630px;
    border-left: 2px dotted #ebeef5;
    border-right: 2px dotted #ebeef5;
}

.el-header.main-header {
    border-bottom: 2px dotted #ebeef5;
    height: 48px !important;
    line-height: 48px !important;
    min-width: 800px;
    //background: #f5f7fa;
}

div.main-title {
    font-size: 18px;
    color: #242424;
    display: flex;
    align-items: center;
    justify-items: center;

    img {
        cursor: pointer;
        width: 36px;
        height: 36px;
    }

    span.bold {
        font-size: 20px;
        font-weight: bold;
        margin: 0 6px 0 6px;
    }

    span.version-span {
        font-size: 14px;
        color: #101f1c;
        margin-left: 6px;
    }
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.el-dropdown-link {
    margin-right: 12px;
    cursor: pointer;
}

div.external-link a {
    font-size: 13px;
    text-decoration: none;
    margin-right: 10px;
    color: #606266;
}

.el-header.toolbar-header {
    font-size: 14px;
    border-bottom: 1px dotted #cccccc;
    height: 42px !important;
    //line-height: 42px !important;
}

.el-aside.side-panel {
    width: 260px !important;
    overflow-y: hidden;
}

.el-main.form-widget-main {
    padding: 0;

    position: relative;
    overflow-x: hidden;
}

.container-scroll-bar {
    ::v-deep .el-scrollbar__wrap,
    ::v-deep .el-scrollbar__view {
        overflow-x: hidden;
    }
}
