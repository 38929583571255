




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'styles/index.styl';

.calc-dialog .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
}
