@import "@/styles/variables.scss"; 










































































































































































.close {
  position:absolute;
  top:0;
  right:0;
  color:#fff;
  cursor:pointer;
  border-radius:50%;
  background:#000
}
.link-avatar {
  display:flex;
  flex-direction:row;
  position:relative;
  align-items:center
}
.link-avatar .a-img {
  display:flex;
  border-radius:50%;
  flex-direction:column;
  justify-content:center;
  background:#fff;
  position:relative
}
.link-avatar .el-avatar {
  background:#fff
}
.link-avatar .name {
  text-align:center;
  color:#19191a;
  font-size:14px;
  margin-left:10px
}
.status {
  position:absolute;
  bottom:-4px;
  right:-8px;
  border-radius:50%;
  font-size:15px;
  background:#fff;
  border:2px solid #fff
}
.read-status {
  position:absolute;
  top:-4px;
  right:-5px;
  border-radius:50%;
  font-size:13px;
  background:#fff;
  border:none;
}
.error {
  color:#f56c6c
}
.leader {
  font-size:12px;
  color:#f78f5f
}
.candidate {
  color:#fff;
  background-color: #f9ae3d;
}
.pending {
  color:#fff;
  background-color: #f78f5f;
}
.success {
  color:#35b881
}
.transfer {
  color:#fff;
  background:#7a939d;
  font-size:12px;
  padding:1px
}
.comment {
  /*background-color:#1989fa;*/
  color:#1989fa;
}
.cc {
  color:#fff;
  font-size:12px;
  padding:1px;
  background:#1989fa
}
.cancel {
  color:#838383
}
.recall {
  color:#f56c6c
}
.show-y {
  justify-content: center;
  flex-direction: column!important;
}
.show-y .name {
  margin-left: 0!important;
}
.line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.process {
  background: #fff;
  padding: 20px 10px 50px 10px;
}
.progress {
  position:relative
}
.progress:after {
  content:"";
  position:absolute;
  width:4px;
  top:0;
  left:18px;
  color:#e4e4e4;
  background:#e4e4e4;
  height:100%
}
.end-process .node-icon span {
  font-size:30px
}
.end-process .node-icon .el-icon-success {
  color:#35b881!important;
  background:#fff!important
}
.end-process .node-icon .el-icon-error {
  color:#f56c6c!important;
  background:#fff!important
}
.end-process .node-icon .el-icon-circle-close {
  color:#979797!important;
  background:#fff!important
}
.end-process .node-icon .el-icon-more {
  color:#b0b0b0!important;
  background:#e4e4e4!important
}
.progress-item {
  z-index:1;
  margin-bottom:30px;
  position:relative
}
.progress-item .item-user>div {
  display:inline-block
}
.progress-item .user-comment {
  margin-left:60px;
  font-size:14px;
  position:relative;
  padding-top:5px
}
.progress-item .user-comment .user-comment-user {
  display:flex;
  align-items:center
}
.progress-item .user-comment .a-img span {
  font-size:12px
}
.progress-item .user-comment .user-comment-time {
  position:absolute;
  right:8px;
  top:7px;
  color:#8c8c8c;
  font-size:small
}
.progress-item .user-comment .user-comment-content {
  margin:5px 0;
  background:#eee;
  padding:5px 20px;
  border-radius:0 5px 5px 5px
}
.progress-item .user-comment .user-comment-content .image-comment {
  padding:5px 0
}
.progress-item .user-comment .user-comment-content .image-comment .image {
  height:60px;
  width:60px;
  padding:5px
}
.progress-item .user-comment .user-comment-content .image-comment img {
  border-radius:5px
}
.progress-item .user-comment .user-comment-content .file-comment .file-item {
  color:#1989fa;
  cursor:pointer
}
.progress-item .item-user-avatar {
  background:#fff;
  position:relative;
  padding:4px 0
}
.progress-item .item-user-avatar i {
  border-radius:50%;
  font-size:15px;
  color:#fff;
  border:2px solid #fff;
  position:absolute;
  padding:0;
  right:-10px;
  top:25px
}
.progress-item .item-user-avatar .el-icon-s-promotion {
  padding:1px;
  font-size:12px
}
.progress-item .item-user-avatar .el-icon-more {
  color:#fff;
  font-size:6px!important
}
.progress-item .item-user-avatar .multi-user-avatar {
  display:flex;
  height:40px;
  width:40px;
  background:#1989fa;
  color:#fff;
  font-size:28px;
  border-radius:4px;
  justify-content:center;
  align-items:center
}
.progress-item .item-user-desc {
  position:absolute;
  left:60px;
  top:5px;
  font-size:14px
}
.progress-item .item-user-desc div:first-child {
  color:#8c8c8c
}
.progress-item .item-user-desc div:nth-child(2) {
  font-size:15px;
  color:#000
}
.read-all {
  color:rgb(52, 137, 255);
  margin-left:20px
}
.time {
  font-size:15px;
  color:#8a8a8a;
  position:absolute;
  right:0;
  top:0
}
.multi-user {
  display:grid;
  margin-left:50px;
  grid-template-columns:repeat(7,60px)
}
.multi-user .item-user-avatar i {
  right:2px
}
