@import "@/styles/variables.scss"; 
html {
  height: 100%;
  box-sizing: border-box;
}

body {
  height: 100%;
  margin: 0; /*  */
  background-color: #ffffff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
}
