@import "@/styles/variables.scss"; 











































































  div.table-container {
    table.table-layout {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;

    }
  }
  .table-container ::v-deep .el-form-item {
    margin: 22px 0;
}
