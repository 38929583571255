@import "@/styles/variables.scss"; 
































































































.object-group-container {
  border: 2px dashed #cccccc;
}

.object-group-container.selected {
  outline: 2px solid $--color-primary !important;
}

.object-group-container {
  // margin: 3px;

  .object-group {
    min-height: 28px;
  }
}


