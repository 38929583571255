@import "@/styles/variables.scss"; 












































































































.container {
    background-color: $base-container-color;
}
.personal-center-user-info {
    padding: 20px;
    text-align: center;
    height: 500px;
}
.personal-center-user-info-full-name{
    margin-top: 15px;
    font-size: 24px;
    font-weight: 500;
    color: #262626;
}
.personal-center-user-info-description {
    margin-top: 8px;
}
.personal-center-user-info-list {
    margin-top: 28px;
    margin-left: 20px;
    margin-right: 20px;
    &::v-deep {
        i {
            line-height: 21px;
        }
    }
}
.form-input{
    width: 60%;
    min-width: 200px;
}

.right-panel{
    height: 500px;
}

