@import "@/styles/variables.scss"; 

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.ai-create-index {
    background: #f6f8f9 !important;
    .delete-btn {
        padding: 13px 15px;
    }
    .btn-bg-primary {
        background-color: var(--color-primary) !important;
        border: 1px solid var(--color-primary) !important;
    }
    .vip-info {
        // height: 100px;

        width: 100%;
        border-radius: 5px;
        // background: #e8f4ff;
        background-color: var(--column-menu-background);
        padding: 20px 10px;
    }
    .text-primary {
        color: var(--color-primary);
    }
    .text-warning {
        color: #ffba00;
    }
    .text-grey {
        color: #999;
    }
    .update-chat-btn {
        background: var(--column-menu-background);
        color: var(--color-primary);
        border-color: var(--column-menu-background);
        &:hover {
            background: var(--color-primary);
            color: #fff;
            border-color: var(--color-primary);
        }
    }
    .answer-avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: fixed;
        right: 20px;
        bottom: 150px;
        // padding: 10px;
        z-index: 111;
        // background: #1d9f3a;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .menu-list {
        padding: 0 15px;
        overflow-y: auto;
        // height: 100%;
        margin-top: 15px;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }
        .menu-item {
            box-shadow: 0 0 6px #dcdfe6;
            background: transparent;
            border-radius: 5px;
            margin-bottom: 15px;
            min-height: 40px;
            // &:hover {
            //     // border: 1px solid #1d9f3a;
            //     background: rgba(29, 159, 58, 0.2);
            //     color: rgb(29, 159, 58);
            // }
            &.active {
                background: var(--column-menu-background);
                color: var(--color-primary);
            }
        }
    }
    .menu-item {
        padding: 5px 15px;
        cursor: pointer;
        background: #f6f8f9;

        &-input {
            height: 40px;
            input {
                // padding: 5px 15px;
                // height: 100%;
                border: none;
                background: transparent;
                width: 100%;
                // height: 100%;
                color: var(--color-primary);
                font-size: 15px;
                &::placeholder {
                    color: var(--color-primary);
                }
                // margin-left: -10px;
                padding: 0 !important;
            }

            border-bottom: 2px solid var(--color-primary);
        }
        &-icon {
            width: 37px;
            height: 37px;
            border-radius: 50%;
            background: #fff;
        }
    }

    .chat {
        &-title {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 20px;
        }
        &-box {
            overflow-y: auto;
            height: 660px;
            margin: 0 -20px;
            padding: 0 20px 20px 20px;
            &-answer {
                display: flex;
                margin-bottom: 20px;
                &-avatar {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background: var(--color-primary);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &-content {
                    // background: #46a6ff;
                    // color: #fff;
                    background: #f6f8f9;
                    padding: 10px;
                    line-height: 1.5;
                    margin-right: 75px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .loading {
                        width: 5px;
                        height: 20px;
                        background: #000;
                        animation: blink 1s infinite;
                    }
                }
            }
            &-ask {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 20px;
                &-avatar {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background: var(--color-primary);
                }
                &-content {
                    background: var(--color-primary);
                    color: #fff;
                    padding: 15px;
                    line-height: 1.5;
                    border-radius: 10px;
                    margin-left: 75px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            .tag-list {
                display: flex;
                flex-wrap: wrap;
                margin: -10px 0;
                // justify-content: center;
                &-item {
                    background: #fff;
                    border: 1px solid #eef2f2;
                    border-radius: 5px;
                    box-shadow: 0 2px 8px #f5f6f8;
                    margin: 10px 15px;
                    padding: 10px;
                    width: 160px;
                    display: flex;
                    justify-content: center;
                    font-size: 14px;
                    cursor: pointer;
                    &:hover {
                        background: var(--column-menu-background);
                        color: var(--color-primary);
                    }
                    &.active {
                        background: var(--column-menu-background);
                        color: var(--color-primary);
                    }
                }
            }
        }
        &-input {
            // margin-right: 15px;
            // background: #f6f8f9;
            border-radius: 4px;
            // border-color:#fff;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

            // width: 100%;
            input {
                // border: none;
                &:focus {
                    outline: 0;

                    border-color: var(--color-primary);
                }
                height: 45px;
                border-radius: 5px;
            }
            textarea {
                border: 1px solid #fff;
                // background: #f6f8f9;
                // height: 45px;
                border-radius: 5px;
            }
            .btn-submit {
                height: 45px;
                /* padding: 0 25px; */
                width: 45px;
                border-radius: 5px;
                color: #fff;
                text-align: center;
                line-height: 45px;
            }
        }
    }
    .btn-bg-primary {
        background-color: var(--color-primary) !important;
        border: 1px solid var(--color-primary) !important;
        height: 42px;
        /* padding: 0 25px; */
        width: 42px;
        border-radius: 5px;
        color: #fff;
        text-align: center;
        line-height: 42px;
    }
    .bottom-10 {
        bottom: 10px;
    }
    .right-15 {
        right: 15px;
    }
    .contextmenu {
        margin: 0;
        background: #fff;
        z-index: 3000;
        position: absolute;
        list-style-type: none;
        width: 500px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
        padding: 15px;
        &-close {
            font-size: 22px;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 15px;
        }
        .operate-menu {
            position: relative;
            // &::after {
            //     position: absolute;
            //     content: "";
            //     width: 0;
            //     height: 0;
            //     border-left: 20px solid transparent; /* 左边透明 */
            //     border-right: 20px solid transparent; /* 右边透明 */
            //     border-bottom: 20px solid #f00;
            // }
            width: 100px;
            margin-top: 5px;
            box-shadow: 1px 0px 2px 1px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            padding: 10px;
            li {
                width: 100%;
                margin: 0;
                padding: 7px 16px;
                cursor: pointer;
                list-style: none;
                &:hover {
                    background: var(--column-menu-background);
                    color: var(--color-primary);
                }
            }
        }
        .chat-box-content {
            // max-height: 200px;
            // overflow-y: auto;
            background: #f6f8f9;
            margin-right: 0;
            margin-top: 15px;
            padding: 10px;
            line-height: 1.5;

            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .answer {
                max-height: 200px;
                overflow-y: auto;
                &::-webkit-scrollbar {
                    width: 6px;

                    height: 10px;
                }
                &::-webkit-scrollbar-track {
                    background: #fff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 20px;
                    background-color: #d3d3d3;
                }
            }
        }
    }
    @keyframes shake {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(-5px);
        }
        50% {
            transform: translateX(5px);
        }
        75% {
            transform: translateX(-5px);
        }
        100% {
            transform: translateX(0);
        }
    }

    .shaking-div {
        animation: shake 0.5s ease infinite; /* 抖动动画，无限循环 */
        display: inline-block; /* 使动画生效 */
    }
    .chat-loading {
        width: 5px;
        height: 20px;
        background: #000 !important;
        animation: chatBlink 1s infinite;
    }
    @keyframes chatBlink {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .el-tabs {
        // height: 667px;
        overflow-x: hidden;
        .el-tabs__content {
            height: 100%;
            padding: 0;
        }
    }
    .el-tabs--left .el-tabs__nav-wrap.is-left.is-scrollable,
    .el-tabs--left .el-tabs__nav-wrap.is-right.is-scrollable,
    .el-tabs--right .el-tabs__nav-wrap.is-left.is-scrollable,
    .el-tabs--right .el-tabs__nav-wrap.is-right.is-scrollable {
        padding: 0;
    }
    .el-tabs__nav-prev {
        display: none;
    }
    .el-tabs__nav-next {
        display: none;
    }
    .el-tabs__nav-scroll .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .el-tabs--left {
        .el-tabs__nav-wrap {
            &.is-left {
                margin-right: 0 !important;
            }

            &.is-left::after {
                width: 0;
                background: #dcdfe6;
            }
        }

        .el-tabs__header {
            &.is-left {
                margin-right: 0 !important;
            }

            .el-tabs__nav-scroll {
                height: 100%;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 0;
                    height: 0;
                    background: transparent;
                }
            }
        }
    }

    .el-tabs--border-card > .el-tabs__header .el-tabs__item {
        font-size: 17px;
        text-align: left;
        &:hover {
            color: var(--color-primary);
        }
    }
    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
        color: var(--color-primary);
    }
    .tag-detail {
        // // width: 40%;
        // height: 200px;
        // background: #f5f6f8;
        // // ma
        padding: 10px 0 0 0 !important;
        margin: 0 !important;
        cursor: pointer;
        li {
            list-style: none;
            padding: 10px 30px;
            margin: 10px 0;
            border-radius: 5px;
            background: #eef2f2;
            // color:#6c6c6c;
            font-size: 15px;
            &:hover {
                background: var(--column-menu-background);
                color: var(--color-primary);
            }
        }
    }
    .divider-line {
        margin: 40px 75px;
    }
    .el-textarea.is-disabled .el-textarea__inner {
        height: 100% !important;
    }
    .el-textarea__inner {
        padding: 10px 80px 10px 15px !important;
        border: 1px solid #fff;
        &:focus {
            outline: 0;

            border-color: var(--color-primary);
        }
        &::-webkit-scrollbar {
            width: 6px;

            height: 8px;
        }
        &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background-color: #d3d3d3;
        }
    }
    .sending-chat {
        position: absolute;
        bottom: 20px;
        width: 100%;
        left: 0;
        padding: 0 35px;
        background: #fff;
    }
}
